import { createSlice } from '@reduxjs/toolkit';
import { agentURLs } from '../urls/userURLs';
import formatDate from '../utils/formatDate';

const AgentSlice = createSlice({
  name: 'agent',
  initialState: {
    logginStatus: localStorage.getItem('agentId') ? true : false,
    account: {
      httpMethod: 'POST',
      locationList: [],
      video: {
        ApartmentNo: '',
        DealType: 'RENT',
        Price: '',
        NoOfBedRooms: '',
        NoOfBathRooms: '',
        PropertyLatitude: '',
        PropertyLongitude: '',
        LocationLatitude: '',
        LocationLongitude: '',
        Address: '',
        Location: '',
        City: '',
        Country: '',
        IsActive: true,
        HideMap: true,
        VideoFile: '',
        VideoId: '',
        AgentId: '',
        NoFee: false,
        StartDate: formatDate(Date.now()),
        EndDate: formatDate(Date.now()),
      },
      videoList: [],
    },
  },
  reducers: {
    addVideoData(state, action) {
      state.account.video[action.payload.key] = action.payload.value;
    },
    videosLoader(state, action) {
      state.account.videoList = action.payload;
    },
    videoUpdater(state, action) {
      console.log("3324324324",JSON.stringify(state.account.videoList));
      const video = state.account.videoList.find(
        (item) => item.videoId === action.payload
      );

      if (video) {
        state.account.httpMethod = 'PUT';
        state.account.video.ApartmentNo = video.apartmentNo || '';
        state.account.video.AgentId = video.agentId || '';
        state.account.video.VideoId = video.videoId || '';
        state.account.video.DealType = video.dealType || 'RENT';
        state.account.video.Price = video.price || '';
        state.account.video.NoOfBedRooms = video.noOfBedRooms === 0 ? 'Studio' : video.noOfBedRooms || '';
        state.account.video.NoOfBathRooms = video.noOfBathRooms || '';
        state.account.video.PropertyLatitude = video.propertyLatitude || '';
        state.account.video.PropertyLongitude = video.propertyLongitude || '';
        state.account.video.LocationLatitude = video.locationLatitude || '';
        state.account.video.LocationLongitude = video.locationLongitude || '';
        state.account.video.Address = video.address || '';
        state.account.video.Location = video.location || '';
        state.account.video.City = video.city || '';
        state.account.video.Country = video.country || '';
        state.account.video.IsActive = video.isActive !== undefined ? video.isActive : true;
        state.account.video.HideMap = video.hideMap !== undefined ? video.hideMap : true;
        state.account.video.VideoFile = video.videoUrl || '';
        state.account.video.NoFee = video.noFee !== undefined ? video.noFee : false;
        state.account.video.StartDate = video.startDate ? formatDate(video.startDate) : formatDate(Date.now());
        state.account.video.EndDate = video.endDate ? formatDate(video.endDate) : formatDate(Date.now());
      }
    },
    locationQueryList(state, action) {
      state.account.locationList = action.payload;
    },
    setLocationDetails(state, action) {
      const location = state.account.locationList.find(
        (item) => item.locationId === action.payload
      );
      if (location) {
        state.account.video.Address = location.address || '';
        state.account.video.PropertyLatitude = location.propertyLatitude || '';
        state.account.video.PropertyLongitude = location.propertyLongitude || '';
        state.account.video.LocationLatitude = location.locationLatitude || '';
        state.account.video.LocationLongitude = location.locationLongitude || '';
        state.account.video.Location = location.location || '';
        state.account.video.Country = location.country || '';
      }
    },
    updateLoginStatus(state, action) {
      state.logginStatus = action.payload;
    },
    resetVideoForm(state) {
      state.account.video = {
        ApartmentNo: '',
        DealType: 'RENT',
        Price: '',
        NoOfBedRooms: '',
        NoOfBathRooms: '',
        PropertyLatitude: '',
        PropertyLongitude: '',
        LocationLatitude: '',
        LocationLongitude: '',
        Address: '',
        Location: '',
        City: '',
        Country: '',
        IsActive: true,
        HideMap: true,
        VideoFile: '',
        VideoId: '',
        AgentId: '',
        NoFee: false,
        StartDate: formatDate(Date.now()),
        EndDate: formatDate(Date.now()),
      };
      state.account.httpMethod = 'POST';
    },
  },
});

export default AgentSlice;
export const AgentActions = AgentSlice.actions;

export const videosloader = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        agentURLs.videoList + localStorage.getItem('agentId')
      );
      if (!response.ok) {
        throw new Error('Failed loading videos');
      }
      const resData = await response.json();
      dispatch(AgentActions.videosLoader(resData.data.data));
    } catch (error) {
      alert(error.message);
    }
  };
};
