import { createSlice } from '@reduxjs/toolkit';

const HomeSlice = createSlice({
  name: 'home',
  initialState: {
    userNav: {
      status: false,
      vidId: undefined,
    },
    videoList: [],
    filters: null,
  },
  reducers: {
    setVideos(state, action) {
      state.videoList = action.payload;
    },

    setUserNavStatus(state, action) {
      state.userNav.status = action.payload.status;
      state.userNav.vidId = action.payload.vidId;
    },
    setAdditionalVideos: (state, action) => {
      if (action.payload === 'noVideo') {
        state.noMoreVideos = true;
      } else {
        const existingVideoNames = new Set(state.videoList.map(video => video.VideoName));
        const newVideos = action.payload.filter(video => !existingVideoNames.has(video.VideoName));
        state.videoList = [...state.videoList, ...newVideos];      }
    },
    resetVideos: (state) => {
      state.videoList = [];
      state.noMoreVideos = false;
    },
    setFilters(state, action) {
      state.filters = JSON.stringify(action.payload);
    },
  },
});

export const { setAdditionalVideos, resetVideos, setFilters } = HomeSlice.actions;
export default HomeSlice;
export const HomeActions = HomeSlice.actions;
