import classes from './CustMessages.module.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { msgURLs } from '../../../urls/userURLs';

const CustMessages = () => {
  const [msgList, setMsgList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    getMessageList();
  }, []);

  const goToDetailsHandle = (data) => {
    fetch(msgURLs.readStatus + localStorage.getItem('userId'), {
      method: 'POST',
      body: JSON.stringify({
        videoId: data.videoId,
        chatId: data.chatId,
      }),
    });
    navigate(`/customer/messages/${data.videoId}`);
  };

  const getMessageList = async (pageNumber = 1) => {
    const response = await fetch(
      msgURLs.msgListUser +
        localStorage.getItem('userId') +
        `&PageNumber=${pageNumber}&PageSize=${10}`
    );
    const resData = await response.json();
    setMsgList(resData.data.data);
    setPageData(resData.data);
  };

  // pagination
  const pageNoHandle = (no) => {
    setPageNo(no);
    getMessageList(no);
  };

  const pageBtns = [];
  let i = 0;
  while (i < pageData.totalPages) {
    i++;
    pageBtns.push(i);
  }

  const prevHandle = () => {
    let pageNum = pageNo;
    if (pageNum > 1) {
      setPageNo(pageNum - 1);
      getMessageList(pageNum - 1);
    }
  };
  const nextHandle = () => {
    let pageNum = pageNo;
    if (pageNum < pageData.totalPages) {
      setPageNo(pageNum + 1);
      getMessageList(pageNum + 1);
    }
  };

  return (
    <div className={classes.custMsg}>
      <div className={classes.custMsg_header}>
        <h1>All Messages</h1>
      </div>
      <div className={classes.custMsg_Footer}>
        <table className={classes.custMsg_Table}>
          <thead>
            <tr className={classes.custMsg_Table_tr}>
              <th>Video details</th>
              <th>Agent</th>
              <th>Last message</th>
              <th>Last activity</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {msgList.map((item) => (
              <tr className={classes.custMsg_Table_tr} key={item.chatId}>
                <td>
                  <div className={classes.agntMsg_table_vid_details}>
                    <p>City:</p>
                    <p>{item.city}</p>
                  </div>
                  <div className={classes.agntMsg_table_vid_details}>
                    <p>No. of bathroom:</p>
                    <p>{item.bath}</p>
                  </div>
                  <div className={classes.agntMsg_table_vid_details}>
                    <p>No. of bedroom:</p>
                    <p>{item.bed}</p>
                  </div>
                </td>
                <td>{item.agentName}</td>
                <td>{item.message}</td>
                <td>{new Date(item.updatedDate).toLocaleString()}</td>
                <td>{item.readStatus ? 'Read' : 'Unread'}</td>
                <td
                  onClick={goToDetailsHandle.bind(null, {
                    videoId: item.videoId,
                    chatId: item.chatId,
                  })}
                  style={{ cursor: 'pointer' }}
                >
                  <span className="material-symbols-outlined">reply</span>Reply
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.agntMsg_Footer_mini}>
        {msgList.map((item) => (
          <ul className={classes.agntMsg_Footer_mini_Ul}>
            <li>
              <h4>City</h4>
              <p>{item.city}</p>
            </li>
            <li>
              <h4>Asset details</h4>
              <p>
                No. of bedrooms-{item.bed} and no. of bathrooms-{item.bath}
              </p>
            </li>
            <li>
              <h4>Agent</h4> <p>{item.agentName}</p>
            </li>
            <li>
              <h4>Last message</h4> <p>{item.message}</p>
            </li>
            <li>
              <h4>Last activity</h4>{' '}
              <p>{new Date(item.updatedDate).toLocaleString()}</p>
            </li>
            <li>
              <h4>Status</h4> <p>{item.readStatus ? 'Read' : 'Unread'}</p>
            </li>
            <li>
              <h4>Action</h4>{' '}
              <p
                onClick={goToDetailsHandle.bind(null, {
                  videoId: item.videoId,
                  chatId: item.chatId,
                })}
                style={{ cursor: 'pointer' }}
              >
                <span className="material-symbols-outlined">reply</span>Reply
              </p>
            </li>
          </ul>
        ))}
      </div>
      <div className={classes.pagination}>
        <button className={classes.pagi_prev} onClick={prevHandle}>
          Prev
        </button>
        <ul className={classes.pagi_btnList}>
          {pageBtns.map((no) => (
            <li onClick={pageNoHandle.bind(null, no)} key={no}>
              <button
                className={
                  pageNo === no ? classes.pagi_btn_active : classes.pagi_btn
                }
              >
                {no}
              </button>
            </li>
          ))}
        </ul>
        <button className={classes.pagi_next} onClick={nextHandle}>
          Next
        </button>
      </div>
    </div>
  );
};
export default CustMessages;
