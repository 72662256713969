import classes from './Input.module.css';
import { useState, forwardRef } from 'react';
// props: --- type, label, name, error

const Input = (props, ref) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  const inputHandle = (e) => {
    const val = e.target.value;
    setValue(val);
    if (val.trim() === '') {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <div className={classes.inputDiv}>
      {props.type === 'date' ? (
        <label className={classes.staticInputLabel}>{props.label}</label>
      ) : (
        <label
          className={value ? classes.inputLabelTransition : classes.inputLabel}
        >
          {props.label}
        </label>
      )}

      <input
        className={classes.input}
        name={props.name}
        type={props.type}
        value={value}
        placeholder={props.placeholder}
        onChange={inputHandle}
        ref={ref}
        required
      ></input>
      {error && <p className={classes.inputError}>{props.error}</p>}
    </div>
  );
};
export default forwardRef(Input);
