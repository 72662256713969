import classes from './AgentMessages.module.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { msgURLs } from '../../../urls/userURLs';

const AgentMessages = () => {
  const [msgList, setMsgList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const navigate = useNavigate();

  // Call default list Once
  useEffect(() => {
    getMessageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToDetailsHandle = (id) => {
    fetch(msgURLs.readStatus + localStorage.getItem('userId'), {
      method: 'POST',
      body: JSON.stringify({
        chatId: id.chat,
        user: id.user,
      }),
    });
    navigate(`/agent/messages/${id.chat}/${id.user}`);
  };

  const getMessageList = async (pageNumber = 1) => {
    const response = await fetch(
      msgURLs.msgListAgent +
        localStorage.getItem('agentId') +
        `&PageNumber=${pageNumber}&PageSize=${10}`
    );
    const resData = await response.json();
    setMsgList(resData.data.data);
    setPageData(resData.data);
  };

  // pagination
  const pageNoHandle = (no) => {
    setPageNo(no);
    getMessageList(no);
  };

  const pageBtns = [];
  let i = 0;
  while (i < pageData.totalPages) {
    i++;
    pageBtns.push(i);
  }

  const prevHandle = () => {
    let pageNum = pageNo;
    if (pageNum > 1) {
      setPageNo(pageNum - 1);
      getMessageList(pageNum - 1);
    }
  };
  const nextHandle = () => {
    let pageNum = pageNo;
    if (pageNum < pageData.totalPages) {
      setPageNo(pageNum + 1);
      getMessageList(pageNum + 1);
    }
  };

  return (
    <div className={classes.agntMsg}>
      <div className={classes.agntMsg_header}>
        <h1>All Messages</h1>
      </div>
      <div className={classes.agntMsg_Footer}>
        <table className={classes.agntMsg_Table}>
          <thead>
            <tr className={classes.agntMsg_Table_tr}>
              <th>Asset info</th>
              <th>Customer</th>
              <th>Last message sent</th>
              <th>Last activity</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {msgList.map((item) => (
              <tr className={classes.agntMsg_Table_tr} key={item.chatId}>
                <td>
                  <div className={classes.agntMsg_table_vid_info}>
                    <div className={classes.agntMsg_table_vid_details}>
                      <p>City:</p>
                      <p>{item.city}</p>
                    </div>
                    <div className={classes.agntMsg_table_vid_details}>
                      <span class="material-symbols-outlined">king_bed</span>
                      <p>{item.bed}</p>
                    </div>
                    <div className={classes.agntMsg_table_vid_details}>
                      <span class="material-symbols-outlined">shower</span>
                      <p>{item.bath}</p>
                    </div>
                  </div>

                  <a
                    className={classes.agntMsg_table_vid_details_link}
                    target="_blank"
                    rel="noreferrer"
                    href={`/video-details/${item.videoId}`}
                  >
                    Go to video
                  </a>
                </td>
                <td>{item.customerName}</td>
                <td>{item.message}</td>
                <td>{new Date(item.updatedDate).toLocaleString()}</td>
                <td>
                  <p className={item.readStatus ? '' : classes.msgStatusHighlight}>
                    {item.readStatus ? 'Read' : 'Unread'}
                  </p>
                </td>
                <td
                  onClick={goToDetailsHandle.bind(null, {
                    chat: item.chatId,
                    user: item.userId,
                  })}
                  style={{ cursor: 'pointer' }}
                >
                  <span className="material-symbols-outlined">reply</span>Reply
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.agntMsg_Footer_mini}>
        {msgList.map((item) => (
          <ul className={classes.agntMsg_Footer_mini_Ul}>
            <li>
              <h4>City</h4>
              <p>{item.city}</p>
            </li>
            <li>
              <h4>Asset details</h4>
              <p>
                No. of bedrooms-{item.bed} and no. of bathrooms-{item.bath}
              </p>
            </li>
            <li>
              <div>&nbsp;</div>
              <a
                className={classes.agntMsg_table_vid_details_link}
                target="_blank"
                rel="noreferrer"
                href={`/video-details/${item.videoId}`}
              >
                Go to video
              </a>
            </li>
            <li>
              <h4>Customer</h4> <p>{item.customerName}</p>
            </li>
            <li>
              <h4>Last message</h4> <p>{item.message}</p>
            </li>
            <li>
              <h4>Last activity</h4>{' '}
              <p>{new Date(item.updatedDate).toLocaleString()}</p>
            </li>
            <li>
              <h4>Status</h4>{' '}
              <span
                className={item.readStatus ? '' : classes.msgStatusHighlight}
              >
                {item.readStatus ? 'Read' : 'Unread'}
              </span>
            </li>
            <li>
              <h4>Action</h4>{' '}
              <p
                onClick={goToDetailsHandle.bind(null, {
                  chat: item.chatId,
                  user: item.userId,
                })}
                style={{ cursor: 'pointer' }}
              >
                <span className="material-symbols-outlined">reply</span>Reply
              </p>
            </li>
          </ul>
        ))}
      </div>
      {msgList[0] && (
        <div className={classes.pagination}>
          <button className={classes.pagi_prev} onClick={prevHandle}>
            Prev
          </button>
          <ul className={classes.pagi_btnList}>
            {pageBtns.map((no) => (
              <li onClick={pageNoHandle.bind(null, no)} key={no}>
                <button
                  className={
                    pageNo === no ? classes.pagi_btn_active : classes.pagi_btn
                  }
                >
                  {no}
                </button>
              </li>
            ))}
          </ul>
          <button className={classes.pagi_next} onClick={nextHandle}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};
export default AgentMessages;
