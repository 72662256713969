import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './pages/RootPage';
import HomePage from './pages/HomePage';
import AgentLogin from './components/forms/agent/AgentLogin';
import AgentRegister from './components/forms/agent/AgentRegister';
import Loginform from './components/forms/customer/Loginform';
import AgentDashboard from './components/forms/agent/AgentDashboard';
import AgentProfile from './components/forms/agent/AgentProfile';
import VideoForm from './components/forms/agent/VideoForm';
import AgentPasswordChange from './components/forms/agent/AgentPasswordChange';
import CustomerDashboard from './components/forms/customer/CustomerDashboard';
import CustomerProfile from './components/forms/customer/CustomerProfile';
import CustomerChangePass from './components/forms/customer/CustomerChangePass';
import VideoDetails from './components/video/VideoDetails';
import AgentMessages from './components/forms/agent/AgentMessages';
import CustMessages from './components/forms/customer/CustMessages';
import AgentMsgDetail from './components/forms/agent/AgentMsgDetail';
import CustMsgDetail from './components/forms/customer/CustMsgDetail';
import { isAgent, isUser } from './pages/AuthLoader';
import ErrorPage from './pages/ErrorPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'agent/login',
        element: <AgentLogin />,
      },
      {
        path: 'agent/signup',
        element: <AgentRegister />,
      },
      {
        path: 'customer',
        element: <Loginform />,
      },
      {
        path: 'agent',
        element: <AgentDashboard />,
        loader: isAgent,
        children: [
          {
            path: 'profile',
            element: <AgentProfile />,
          },
          {
            path: 'video',
            element: <VideoForm />,
          },
          {
            path: 'messages',
            element: <AgentMessages />,
          },
          {
            path: 'messages/:chatId/:customerUserId',
            element: <AgentMsgDetail />,
          },
          {
            path: 'change-password',
            element: <AgentPasswordChange />,
          },
        ],
      },
      {
        path: 'video-details/:vidId',
        element: <VideoDetails />,
      },
      {
        path: 'customer',
        element: <CustomerDashboard />,
        loader: isUser,
        children: [
          {
            path: 'profile',
            element: <CustomerProfile />,
          },
          {
            path: 'messages',
            element: <CustMessages />,
          },
          {
            path: 'messages/:videoId',
            element: <CustMsgDetail />,
          },
          {
            path: 'change-password',
            element: <CustomerChangePass />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
