  const domain = `https://theproppyapp.azurewebsites.net`;

//  const domain = `https://localhost:44363`;


export const homeURLs = {
  allVideos: `${domain}/api/Video/GetActive`,
  getLocation: `${domain}/api/home/location?input=`,
  getVideoDetailsById: `${domain}/api/Video/GetVideoById?VideoId=`,
  getCities: `${domain}/api/Video/GetLocations`,
};
