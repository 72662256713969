import classes from './Loginform.module.css';
import Input from '../Input';
import { useEffect, useRef, useState } from 'react';
import { agentURLs, googleLogUrls } from '../../../urls/userURLs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerAction } from '../../../store/customerSlice';
import { jwtDecode } from 'jwt-decode';

const Loginform = () => {
  const [submitMsg, setSubmitMsg] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [signup, setSignup] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state.home);

  const handleGoogleResponse = async (response) => {
    const decoded = jwtDecode(response.credential);

    const body = new FormData();

    if (decoded) {
      body.append('aud', decoded.aud);
      body.append('azp', decoded.azp);
      body.append('email', decoded.email);
      body.append('email_verified', decoded.email_verified);
      body.append('exp', decoded.exp);
      body.append('given_name', decoded.given_name);
      body.append('iat', decoded.iat);
      body.append('iss', decoded.iss);
      body.append('jti', decoded.jti);
      body.append('locale', decoded.locale);
      body.append('name', decoded.name);
      body.append('nbf', decoded.nbf);
      body.append('picture', decoded.picture);
      body.append('sub', decoded.sub);
      body.append('usertype', 'Customer');

      const res = await fetch(googleLogUrls.signIn, {
        method: 'POST',
        body,
      });
      const resData = await res.json();

      if (resData.data.status === true && resData.data.role === 'Customer') {
        localStorage.setItem('name', resData.data.name);
        localStorage.setItem('role', resData.data.role);
        localStorage.setItem('status', resData.data.status);
        localStorage.setItem('token', resData.data.token);
        localStorage.setItem('userId', resData.data.userId);
        localStorage.setItem('agentId', resData.data.agentId);
        localStorage.setItem('msgCount', resData.data.messageCount);
        dispatch(CustomerAction.updateLoginStatus(true));
        setResponseStatus(true);
        navigate(
          `${
            homeState.userNav.status
              ? `/customer/messages/${homeState.userNav.vidId}`
              : '/customer/messages'
          }`
        );
      } else {
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        localStorage.removeItem('status');
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('agentId');
        localStorage.removeItem('msgCount');
        setResponseStatus(false);
        setSubmitMsg('Access Denied');
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (google) {
      // eslint-disable-next-line no-undef
      google.accounts.id.initialize({
        client_id:
          '1033443409098-7h9dt6pgddtqrn9vsn4op0gs8n9od4tg.apps.googleusercontent.com',
        callback: handleGoogleResponse,
      });
      // eslint-disable-next-line no-undef
      google.accounts.id.renderButton(
        document.getElementById('googleSigInBtn'),
        {
          width: 240,
          height: 50,
          longtitle: true,
          theme: 'dark',
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signupHandle = () => {
    setSignup(!signup);
  };

  const loginHandle = async (e) => {
    if (
      emailRef.current.value.trim().length === 0 ||
      passwordRef.current.value.trim().length === 0
    ) {
      setSubmitMsg('Please enter both email and password.');
      setResponseStatus(false);
    } else {
      setSubmitting(true);
      setSubmitMsg('');
      e.preventDefault();

      let body;
      if (signup) {
        body = {
          email: emailRef.current.value,
          password: passwordRef.current.value,
          name: nameRef.current.value,
          phone: phoneRef.current.value,
          userType: 'Customer',
        };
      } else {
        body = {
          email: emailRef.current.value,
          password: passwordRef.current.value,
          userType: 'Customer',
        };
      }

      const response = await fetch(agentURLs.login, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const resData = await response.json();

      if (resData.data.status === true && resData.data.role === 'Customer') {
        localStorage.setItem('name', resData.data.name);
        localStorage.setItem('role', resData.data.role);
        localStorage.setItem('status', resData.data.status);
        localStorage.setItem('token', resData.data.token);
        localStorage.setItem('userId', resData.data.userId);
        localStorage.setItem('agentId', resData.data.agentId);
        localStorage.setItem('msgCount', resData.data.messageCount);
        dispatch(CustomerAction.updateLoginStatus(true));
        setResponseStatus(true);
        navigate(
          `${
            homeState.userNav.status
              ? `/customer/messages/${homeState.userNav.vidId}`
              : '/customer/messages'
          }`
        );
      } else {
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        localStorage.removeItem('status');
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('agentId');
        localStorage.removeItem('msgCount');
        setSubmitMsg(resData.data.message);
        setResponseStatus(true);
      }
      setSubmitting(false);
    }
  };

  return (
    <form className={classes.loginform}>
      <div className={classes.loginformManual}>
        <p className={classes.loginformHead}>{`${
          signup ? 'Signup' : 'Login'
        }`}</p>
        <Input
          type="text"
          label="Email"
          name="agentLogEmail"
          error="Please enter your email"
          placeholder="Email"
          ref={emailRef}
        ></Input>
        <Input
          type="password"
          label="Password"
          name="agentLogPass"
          error="Please enter your password"
          placeholder="Password"
          ref={passwordRef}
        ></Input>
        {signup && (
          <>
            <Input
              type="text"
              label="Name"
              name="agentLogName"
              error="Please enter your Name"
              placeholder="Name"
              ref={nameRef}
            ></Input>
            <Input
              type="number"
              label="Phone Number"
              name="agentLogPhone"
              error="Please enter your phone number"
              placeholder="Phone Number"
              ref={phoneRef}
            ></Input>
          </>
        )}
        <button className={classes.custLoginBtn} onClick={loginHandle}>
          {submitting ? 'Please wait...' : `${signup ? 'Submit' : 'Login'}`}
        </button>
        <p
          className={`${
            responseStatus ? classes.submitMsgSuccess : classes.submitMsg
          }`}
        >
          {submitMsg}
        </p>
        <div>
          <p className={classes.signupLinkMsg}>
            {signup
              ? 'Are you already registered? Click for'
              : "Don't have a customer account? Click for"}
          </p>
          <button className={classes.signupLink} onClick={signupHandle}>
            {signup ? ' Login' : 'Signup'}
          </button>
        </div>
      </div>
      <p className={classes.seperator}>Or</p>
      <div className={classes.googleSignBox}>
        <div id="googleSigInBtn" className={classes.googleSign}></div>
        <p
          className={`${
            responseStatus ? classes.submitMsgSuccess : classes.submitMsg
          }`}
        >
          {submitMsg}
        </p>
      </div>
    </form>
  );
};
export default Loginform;
