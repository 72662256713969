import classes from './Video.module.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Video = ({ data }) => {
  const [showDetailBtn, setShowDetailBtn] = useState(true);
  const [showVidLoading, setShowVidLoading] = useState(true);
  const navigate = useNavigate();

  const videoloadedhandle = () => {
    setShowDetailBtn(true);
    setShowVidLoading(false);
  };

  const viewDetailsHandle = (vidId) => {
    navigate('video-details/' + vidId + '/#detailsSection');
  };

  return (
    <div className={classes.video}>
      {showVidLoading && (
        <h2 className={classes.video_loading}>Loading video...</h2>
      )}
      <div className={classes.videoBox}>
        {data.noFee === true && (
          <p className={classes.noFeeTag}>NO FEE RENTAL</p>
        )}
        <video
          playsInline
          controls
          muted
          className={classes.vid}
          onLoadedData={videoloadedhandle}
        >
          <source src={data.videoUrl} />
        </video>
      </div>
      <div className={classes.details}>
        <p className={classes.detailsInfo}>
          Deal Type:{' '}
          <span className={classes.detailsInfoData}>{data.dealType}</span>
        </p>
        <p className={classes.detailsInfo}>
          Bed:{' '}
          <span className={classes.detailsInfoData}>
            {data.noOfBedRooms === 0 ? 'Studio' : data.noOfBedRooms}
          </span>
        </p>
        <p className={classes.detailsInfo}>
          Bathroom:{' '}
          <span className={classes.detailsInfoData}>{data.noOfBathRooms}</span>
        </p>
        <p className={classes.detailsInfo}>
          Price: <span className={classes.detailsInfoData}>{data.price}</span>
        </p>
        <p className={classes.detailsInfo}>
          City: <span className={classes.detailsInfoData}>{data.city}</span>
        </p>
      </div>
      {showDetailBtn && (
        <button
          className={classes.vidDetailBtn}
          onClick={() => viewDetailsHandle(data.videoId)}
        >
          View Details
        </button>
      )}
    </div>
  );
};
export default Video;
