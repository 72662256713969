import classes from './SideNav.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CustomerAction } from '../../store/customerSlice';
import { AgentActions } from '../../store/agentSlice';

const SideNav = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutHandle = () => {
    localStorage.removeItem('name');
    localStorage.removeItem('role');
    localStorage.removeItem('status');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('agentId');
    if (props.reduxLogout === 'Customer') {
      dispatch(CustomerAction.updateLoginStatus(false));
    }
    if (props.reduxLogout === 'Agent') {
      dispatch(AgentActions.updateLoginStatus(false));
    }
    navigate('/');
  };
  const url = window.location.href;
  const activeTab = url.split('/').pop();

  return (
    <div className={classes.agnt_acc_panel}>
      {props.links.map((link, i) => (
        <Link
          key={i}
          className={`${classes.panelLinks} ${
            link.activeTab === activeTab ? classes.linkHiglight : ''
          }`}
          to={link.url}
        >
          <span className={`${classes.panelIcons} material-symbols-outlined`}>
            {link.googleIconName}
          </span>
          <p className={classes.linkText}>{link.title}</p>
          {link.url === '/agent/messages' && (
            <p
              className={
                link.activeTab === activeTab
                  ? classes.linkNotification
                  : classes.linkNotifInactive
              }
            >
              {localStorage.getItem('msgCount')}
            </p>
          )}
        </Link>
      ))}

      <div className={classes.panelLinks} onClick={logoutHandle}>
        <span className={`${classes.panelIcons} material-symbols-outlined`}>
          logout
        </span>
        <p className={classes.linkText}>Logout</p>
      </div>
    </div>
  );
};
export default SideNav;
