import classes from './Header.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const agentState = useSelector((state) => state.agent);
  const customerState = useSelector((state) => state.customer);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const directHome = () => {
    navigate('/');
  };

  const role = localStorage.getItem('role');
  let dashboardLink = '/';
  if (role === 'Agent' && localStorage.getItem('msgCount') > 0) {
    dashboardLink = 'agent/messages';
  } else {
    dashboardLink = 'agent/video';
  }
  if (role === 'Customer') dashboardLink = 'customer/messages';

  return (
    <div
      className={classes.header}
      onClick={props.signupFormView}
      key={agentState.logginStatus || customerState.loginStatus}
    >
      <div className={classes.socialIcons}>
        {/* <img src="/images/facebook.png" alt="facebook"></img>
        <img src="/images/insta.png" alt="insta"></img> */}
      </div>
      <img
        className={classes.logo}
        src="/images/logo.png"
        alt="logo"
        onClick={directHome}
      ></img>
      <div className={classes.user}>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? classes.userLink_active : classes.userLink
          }
        >
          <span className={`${classes.navicons} material-symbols-outlined`}>
            home
          </span>
          Home
        </NavLink>
        {(!localStorage.getItem('userId') ||
          !localStorage.getItem('agentId')) && (
          <>
            <NavLink
              to="/customer"
              className={({ isActive }) =>
                isActive ? classes.userLink_active : classes.userLink
              }
            >
              <span className={`${classes.navicons} material-symbols-outlined`}>
                how_to_reg
              </span>
              Customer
            </NavLink>
            <NavLink
              to="/agent/login"
              className={({ isActive }) =>
                isActive ? classes.userLink_active : classes.userLink
              }
            >
              <span className={`${classes.navicons} material-symbols-outlined`}>
                badge
              </span>
              Agent
            </NavLink>
          </>
        )}
        {(localStorage.getItem('userId') ||
          localStorage.getItem('agentId')) && (
          <NavLink
            to={dashboardLink}
            className={({ isActive }) =>
              isActive ? classes.userLink_active : classes.userLink
            }
          >
            <span className={`${classes.navicons} material-symbols-outlined`}>
              dashboard
            </span>
            My Dashboard
          </NavLink>
        )}
      </div>
      <div className={classes['hamburger-menu']}>
        <div
          className={`${classes['menu-button']} ${isOpen ? 'open' : ''}`}
          onClick={toggleMenu}
        >
          <div className={classes['bar']}></div>
          <div className={classes['bar']}></div>
          <div className={classes['bar']}></div>
        </div>
        {isOpen && (
          <ul className={classes['menu-items']}>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? classes.userLink_active : classes.userLink
                }
                onClick={toggleMenu}
              >
                <span
                  className={`${classes.navicons} material-symbols-outlined`}
                >
                  home
                </span>
                Home
              </NavLink>
            </li>
            {(!localStorage.getItem('userId') ||
              !localStorage.getItem('agentId')) && (
              <>
                <li>
                  <NavLink
                    to="/customer"
                    className={({ isActive }) =>
                      isActive ? classes.userLink_active : classes.userLink
                    }
                    onClick={toggleMenu}
                  >
                    <span
                      className={`${classes.navicons} material-symbols-outlined`}
                    >
                      how_to_reg
                    </span>
                    Customer
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/agent/login"
                    className={({ isActive }) =>
                      isActive ? classes.userLink_active : classes.userLink
                    }
                    onClick={toggleMenu}
                  >
                    <span
                      className={`${classes.navicons} material-symbols-outlined`}
                    >
                      badge
                    </span>
                    Agent
                  </NavLink>
                </li>
              </>
            )}
            {(localStorage.getItem('userId') ||
              localStorage.getItem('agentId')) && (
              <li>
                <NavLink
                  to={dashboardLink}
                  className={({ isActive }) =>
                    isActive ? classes.userLink_active : classes.userLink
                  }
                  onClick={toggleMenu}
                >
                  <span
                    className={`${classes.navicons} material-symbols-outlined`}
                  >
                    dashboard
                  </span>
                  My dashboard
                </NavLink>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Header;
