import { createSlice } from '@reduxjs/toolkit';

const CustomerSlice = createSlice({
  name: 'customer',
  initialState: {
    loginStatus: localStorage.getItem('userId') ? true : false,
  },
  reducers: {
    updateLoginStatus(state, action) {
      state.loginStatus = action.payload;
    },
  },
});

export default CustomerSlice;
export const CustomerAction = CustomerSlice.actions;
