import './ErrorPage.css';
const ErrorPage = () => {
  return (
    <section className="page_404">
      <div className="four_zero_four_bg"></div>
      <div className="contant_box_404">
        <h1 className="h1">Look like you're lost</h1>

        <p className="p" style={{ fontSize: '2rem', color: 'darkgreen' }}>
          the page you are looking for not avaible!
        </p>
        <p className="p">or something went wrong.</p>
        <p className="p">Try after sometime.</p>

        <a href="/" className="link_404">
          Go to Home
        </a>
      </div>
    </section>
  );
};
export default ErrorPage;
