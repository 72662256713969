import classes from './CustomerChangePass.module.css';
import Input from '../Input';
import { useRef, useState } from 'react';
import { agentURLs } from '../../../urls/userURLs';

const CustomerChangePass = () => {
  const [submitMsg, setSubmitMsg] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const oldPassRef = useRef();
  const newPassRef = useRef();
  const confirmPassRef = useRef();

  const updateHandle = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (
      oldPassRef.current.value.trim().length === 0 ||
      newPassRef.current.value.trim().length === 0 ||
      confirmPassRef.current.value.trim().length === 0
    ) {
      setSubmitMsg('Please enter all input fields.');
      setResponseStatus(false);
    } else if (newPassRef.current.value.trim().length < 8) {
      setResponseStatus(false);
      setSubmitMsg('Please enter password of min 8 character');
    } else if (newPassRef.current.value !== confirmPassRef.current.value) {
      setResponseStatus(false);
      setSubmitMsg('Confirm password did not match new password');
    } else {
      const response = await fetch(agentURLs.changePass, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: localStorage.getItem('userId'),
          oldPassword: oldPassRef.current.value,
          newPassword: newPassRef.current.value,
        }),
      });
      if (!response.ok) {
        const resData = await response.json();
        setSubmitMsg(resData.data);
        setResponseStatus(false);
      } else {
        const resData = await response.json();
        setSubmitMsg(resData.data);
        setResponseStatus(true);
      }
    }

    setSubmitting(false);
  };

  return (
    <div className={classes.apc}>
      <form className={classes.apc_section}>
        <h1 className={classes.apc_head}>Change your password</h1>
        <Input
          type="password"
          label="Old Password"
          name="agentOldPassword"
          error="Please enter your old password"
          placeholder="Old Password"
          ref={oldPassRef}
        ></Input>
        <Input
          type="password"
          label="New Password"
          name="agentNewPassword"
          error="Please enter your new password"
          placeholder="New Password"
          ref={newPassRef}
        ></Input>
        <Input
          type="password"
          label="Confirm Password"
          name="agentConfirmPassword"
          error="Please confirm your password"
          placeholder="Confirm Password"
          ref={confirmPassRef}
        ></Input>
        <button className={classes.agntPassBtn} onClick={updateHandle}>
          {submitting ? 'Please wait...' : 'Update'}
        </button>
        <p
          className={`${
            responseStatus ? classes.submitMsgSuccess : classes.submitMsg
          }`}
        >
          {submitMsg}
        </p>
      </form>
    </div>
  );
};
export default CustomerChangePass;
