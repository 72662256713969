import classes from './VideoDetails.module.css';
import { useEffect, useState } from 'react';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';
import '../../../node_modules/video-react/dist/video-react.css';
import { useParams } from 'react-router-dom';
import GoogleMapViewer from '../../utils/GoogleMapViewer';
import { homeURLs } from '../../urls/publicURLs';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HomeActions } from '../../store/homeSlice';

const VideoDetails = () => {
  const [details, setDetails] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { vidId } = useParams();

  useEffect(() => {
    vidDetailsLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vidDetailsLoad = async () => {
    const response = await fetch(homeURLs.getVideoDetailsById + vidId);
    const resData = await response.json();
    if (resData.data && resData.data[0]) setDetails(resData.data[0]);
  };

  const showChatHandle = () => {
    if (
      localStorage.getItem('userId') &&
      localStorage.getItem('role') === 'Customer'
    ) {
      navigate(`/customer/messages/${vidId}`);
    } else if (
      localStorage.getItem('agentId') &&
      localStorage.getItem('role') === 'Agent'
    ) {
      navigate(`/agent/messages`);
    } else {
      dispatch(
        HomeActions.setUserNavStatus({
          status: true,
          vidId: vidId,
        })
      );
      navigate('/customer');
    }
  };

  console.log(details);

  return (
    <div className={classes.vidDetails} key={details.videoUrl}>
      <Player
        playsInline={true}
        fluid={false}
        width={'100%'}
        height={600}
        className={classes.videoImg}
      >
        <LoadingSpinner />
        <source src={details.videoUrl} />
        <BigPlayButton position="center" />
      </Player>
      <button className={classes.chatBtn} onClick={showChatHandle}>
        <span className="material-symbols-outlined">sms</span> Connect with
        agent
      </button>

      <ul className={classes.vid_details_text} id="detailsSection">
        <li>
          <p className={classes.vid_d_t_head}>Deal Type: </p>
          <p>{details.dealType}</p>
        </li>
        <li>
          <p className={classes.vid_d_t_head}>Bed: </p>
          <p>{details.noOfBedRooms}</p>
        </li>
        <li>
          <p className={classes.vid_d_t_head}>Bathroom: </p>
          <p>{details.noOfBathRooms}</p>
        </li>
        <li>
          <p className={classes.vid_d_t_head}>Price: </p>
          <p>{details.price}</p>
        </li>
        <li>
          <p className={classes.vid_d_t_head}>City: </p>
          <p>{details.city}</p>
        </li>
        {!details.hideMap && (
          <li>
            <p className={classes.vid_d_t_head}>Address: </p>
            <p>{details.address}</p>
          </li>
        )}
      </ul>
      <div className={classes.vid_detail_map}>
        <GoogleMapViewer
          center={{
            lat: parseFloat(details.propertyLatitude),
            lng: parseFloat(details.propertyLongitude),
          }}
          zoom={16}
          style={{ width: '100%', height: '400px' }}
          hideMap={details.hideMap}
        />
      </div>
    </div>
  );
};
export default VideoDetails;
