import classes from './Footer.module.css';

const Footer = () => {
  return (
    <div className={classes.footer}>
    <p>Please contact vishy@theproppy.com, if you have any questions, concerns or feedback</p>
      <p>www.theproppy.com | &copy; All rights reserved 2024</p>
    </div>
  );
};
export default Footer;
