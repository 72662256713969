import { customerURLs } from '../../../urls/userURLs';
import classes from './CustomerProfile.module.css';
import { useState, useRef, useEffect } from 'react';

const CustomerProfile = () => {
  const [previousData, setPreviousData] = useState(null);
  const [submitMsg, setSubmitMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();

  useEffect(() => {
    getPreviousData();
  }, []);

  const getPreviousData = async () => {
    const response = await fetch(
      customerURLs.getUserById + localStorage.getItem('userId')
    );
    if (!response.ok) {
      const resData = await response.json();
      setSubmitMsg(
        resData && resData.errors && Object.values(resData.errors).join(', ')
      );
    } else {
      const resData = await response.json();
      setPreviousData(resData.data);
    }
  };

  const updateHandle = async (e) => {
    setSubmitting(true);
    e.preventDefault();

    if (
      nameRef.current.value.trim().length === 0 ||
      emailRef.current.value.trim().length === 0 ||
      phoneRef.current.value.trim().length === 0
    ) {
      setSubmitMsg('Please complete all the fields with asterick *');
      setResponseStatus(false);
    } else {
      setSubmitMsg('');

      const body = new FormData();
      body.append('AgentId', localStorage.getItem('agentId'));
      body.append('UserId', localStorage.getItem('userId'));
      body.append('Email', emailRef.current.value);
      body.append('PhoneNumber', phoneRef.current.value);
      body.append('Name', nameRef.current.value);
      body.append('City', cityRef.current.value);
      body.append('Country', countryRef.current.value);

      const response = await fetch(customerURLs.updateProfile, {
        method: 'POST',
        body,
      });

      if (!response.ok) {
        const resData = await response.json();
        setSubmitMsg(Object.values(resData.errors).join(', '));
        setResponseStatus(false);
      } else {
        const resData = await response.json();
        setSubmitMsg(resData.data);
        setResponseStatus(true);
      }
    }
    setSubmitting(false);
  };

  return (
    <form className={classes.agnt_profile}>
      <h1 className={classes.agnt_profileHead}>Update Profile</h1>
      <div className={classes.agentInputDiv}>
        <label
          className={`${classes.agnt_profileLabels} ${classes.agnt_profileLabels_astreik}`}
        >
          Full Name
        </label>
        <input
          className={classes.agnt_profileInputs}
          type="text"
          placeholder="Full Name"
          defaultValue={previousData && previousData.name}
          ref={nameRef}
        ></input>
      </div>
      <div className={classes.agentInputDiv}>
        <label
          className={`${classes.agnt_profileLabels} ${classes.agnt_profileLabels_astreik}`}
        >
          Email
        </label>
        <input
          className={classes.agnt_profileInputs}
          type="email"
          placeholder="Email"
          defaultValue={previousData && previousData.email}
          ref={emailRef}
        ></input>
      </div>
      <div className={classes.agentInputDiv}>
        <label
          className={`${classes.agnt_profileLabels} ${classes.agnt_profileLabels_astreik}`}
        >
          Phone Number
        </label>
        <input
          className={classes.agnt_profileInputs}
          type="number"
          placeholder="Phone Number"
          defaultValue={previousData && previousData.phoneNumber}
          ref={phoneRef}
        ></input>
      </div>
      <div className={classes.agentInputDiv}>
        <label className={classes.agnt_profileLabels}>City</label>
        <input
          className={classes.agnt_profileInputs}
          type="text"
          placeholder="City"
          defaultValue={previousData && previousData.city}
          ref={cityRef}
        ></input>
      </div>
      <div className={classes.agentInputDiv}>
        <label className={classes.agnt_profileLabels}>Country</label>
        <input
          className={classes.agnt_profileInputs}
          type="text"
          placeholder="Country"
          defaultValue={previousData && previousData.country}
          ref={countryRef}
        ></input>
      </div>
      <button className={classes.vidProfileBtn} onClick={updateHandle}>
        {submitting ? 'Please wait...' : 'Update'}
      </button>
      <p
        className={`${
          responseStatus ? classes.submitMsgSuccess : classes.submitMsg
        }`}
      >
        {submitMsg}
      </p>
    </form>
  );
};

export default CustomerProfile;
