import { agentURLs } from '../../../urls/userURLs';
import classes from './AgentProfile.module.css';
import { useState, useRef, useEffect } from 'react';
import { homeURLs } from '../../../urls/publicURLs';

const AgentProfile = () => {
  const [previousData, setPreviousData] = useState(null);
  const [doc, setDoc] = useState(null);
  const [submitMsg, setSubmitMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [location, setLocation] = useState('');
  const [country, setCountry] = useState('');
  const [showList, setShowList] = useState(false);
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();

  useEffect(() => {
    getPreviousData();
  }, []);

  const getPreviousData = async () => {
    const response = await fetch(
      agentURLs.getAgentById + localStorage.getItem('agentId')
    );
    if (!response.ok) {
      const resData = await response.json();
      setSubmitMsg(
        resData && resData.errors && Object.values(resData.errors).join(', ')
      );
    } else {
      const resData = await response.json();
      setPreviousData(resData.data);
    }
  };

  const licenseHandle = (e) => {
    setDoc(e.target.files[0]);
  };

  const addressHandle = async (e) => {
    setShowList(true);
    const val = e.target.value;
    setAddress(val);
    if (val.trim().length > 3) {
      const response = await fetch(homeURLs.getLocation + val);
      const resData = await response.json();
      setAddressList(resData.data);
    }
  };

  const onClickAddressHandle = (item) => {
    setAddress(item.address);
    setCity(item.city);
    setLocation(item.location);
    setCountry(item.country);
    setShowList(false);
  };

  const updateHandle = async (e) => {
    setSubmitting(true);
    e.preventDefault();

    if (
      !nameRef.current.value.trim().length === 0 ||
      !emailRef.current.value.trim().length === 0 ||
      !phoneRef.current.value.trim().length === 0 ||
      !doc
    ) {
      setSubmitMsg('Please complete all the fields with asterick *');
      setResponseStatus(false);
    } else {
      setSubmitMsg('');

      const body = new FormData();
      body.append('AgentId', localStorage.getItem('agentId'));
      body.append('UserId', localStorage.getItem('userId'));
      body.append('Email', emailRef.current.value);
      body.append('PhoneNumber', phoneRef.current.value);
      body.append('Name', nameRef.current.value);
      body.append('LicenseFile', doc || null);
      body.append('Address', address);
      body.append('Location', location);
      body.append('City', city);
      body.append('Country', country);

      const response = await fetch(agentURLs.updateProfile, {
        method: 'PUT',
        body,
      });

      if (!response.ok) {
        const resData = await response.json();
        setSubmitMsg(resData.data || Object.values(resData.errors).join(', '));
        setResponseStatus(false);
      } else {
        const resData = await response.json();
        setResponseStatus(true);
        setSubmitMsg(resData.data);
      }
    }
    setSubmitting(false);
  };

  return (
    <form className={classes.agnt_profile}>
      <h1 className={classes.agnt_profileHead}>Update Profile</h1>
      <div className={classes.agentInputDiv}>
        <label
          className={`${classes.agnt_profileLabels} ${classes.agnt_profileLabels_astreik}`}
        >
          Full Name
        </label>
        <input
          className={classes.agnt_profileInputs}
          type="text"
          placeholder="Full Name"
          defaultValue={previousData && previousData.name}
          ref={nameRef}
        ></input>
      </div>
      <div className={classes.agentInputDiv}>
        <label
          className={`${classes.agnt_profileLabels} ${classes.agnt_profileLabels_astreik}`}
        >
          Email
        </label>
        <input
          className={classes.agnt_profileInputs}
          type="email"
          placeholder="Email"
          defaultValue={previousData && previousData.email}
          ref={emailRef}
        ></input>
      </div>
      <div className={classes.agentInputDiv}>
        <label
          className={`${classes.agnt_profileLabels} ${classes.agnt_profileLabels_astreik}`}
        >
          Phone Number
        </label>
        <input
          className={classes.agnt_profileInputs}
          type="number"
          placeholder="Phone Number"
          defaultValue={previousData && previousData.phoneNumber}
          ref={phoneRef}
        ></input>
      </div>
      <div className={classes.agentInputDiv}>
        <label className={classes.agnt_profileLabels}>Address</label>
        <input
          className={classes.agnt_profileInputs}
          type="text"
          placeholder="Address"
          onChange={addressHandle}
          value={address || (previousData && previousData.address)}
        ></input>
        {showList && (
          <ul className={classes.addressList}>
            {addressList.map((item) => (
              <li
                key={item.locationId}
                onClick={onClickAddressHandle.bind(null, item)}
              >
                {item.address}
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* <div className={classes.agentInputDiv}>
        <label className={classes.agnt_profileLabels}>Location</label>
        <input
          className={classes.agnt_profileInputs}
          type="text"
          placeholder="Location"
          value={location || (previousData && previousData.location)}
        ></input>
      </div>
      <div className={classes.agentInputDiv}>
        <label className={classes.agnt_profileLabels}>City</label>
        <input
          className={classes.agnt_profileInputs}
          type="text"
          placeholder="City"
          value={city || (previousData && previousData.city)}
        ></input>
      </div>
      <div className={classes.agentInputDiv}>
        <label className={classes.agnt_profileLabels}>Country</label>
        <input
          className={classes.agnt_profileInputs}
          type="text"
          placeholder="Country"
          value={country || (previousData && previousData.country)}
        ></input>
      </div> */}
      <div className={classes.uploadProficenseDiv}>
        <label
          className={`${classes.agnt_profileLabels} ${classes.agnt_profileLabels_astreik}`}
          htmlFor="uploadLicense"
        >
          {doc ? 'Change file or download' : 'Upload license (.jpg / .pdf)'}
        </label>
        <input
          onChange={licenseHandle}
          type="file"
          id="uploadLicense"
          accept="image/jpeg, application/pdf,image/png, image/gif,image/jpg"
        ></input>
        {doc && (
          <a
            download={doc}
            href={URL.createObjectURL(doc)}
            className={classes.uploadProficenseBtn}
          >
            <span className="material-symbols-outlined">download</span>
          </a>
        )}
      </div>
      <small style={{ fontSize: '1.2rem' }}>* Fields are mandatory</small>
      <button className={classes.vidProfileBtn} onClick={updateHandle}>
        {submitting ? 'Please wait...' : 'Update'}
      </button>
      <p
        className={`${
          responseStatus ? classes.submitMsgSuccess : classes.submitMsg
        }`}
      >
        {submitMsg}
      </p>
    </form>
  );
};

export default AgentProfile;
