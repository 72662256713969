import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Outlet } from 'react-router';
import BodyWrapper from '../utils/BodyWrapper';

const Root = () => {
  return (
    <>
      <Header />
      <BodyWrapper>
        <Outlet />
      </BodyWrapper>
      <Footer />
    </>
  );
};
export default Root;
