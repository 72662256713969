import classes from './CustomerDashboard.module.css';
import SideNav from '../../header/SideNav';
import { Outlet } from 'react-router';

const CustomerDashboard = () => {
  const links = [
    {
      activeTab: 'messages',
      url: '/customer/messages',
      googleIconName: 'mail',
      title: 'Messages',
    },
    {
      activeTab: 'profile',
      url: '/customer/profile',
      googleIconName: 'person',
      title: 'Profile',
    },

    {
      activeTab: 'change-password',
      url: '/customer/change-password',
      googleIconName: 'key',
      title: 'Change Password',
    },
  ];
  return (
    <div className={classes.agnt_acc}>
      <SideNav reduxLogout={'Customer'} links={links} />
      <Outlet />
    </div>
  );
};
export default CustomerDashboard;
