  const domain = `https://theproppyapp.azurewebsites.net`;
// const domain = `https://localhost:44363`;
export const agentURLs = {
  login: `${domain}/api/home/login`,
  changePass: `${domain}/api/User/ChangePassword`,
  register: `${domain}/api/home/register-agent`,
  uploadVideo: `${domain}/api/Video/upload`,
  modifyVideo: `${domain}/api/Video/`,
  videoList: `${domain}/api/Video/GetByAgent?AgentId=`,
  deleteVideo: `${domain}/api/Video/`,
  updateProfile: `${domain}/api/Agent/`,
  getAgentById: `${domain}/api/Agent/`,
};

export const customerURLs = {
  updateProfile: `${domain}/api/User/Profile`,
  googleSign: `${domain}/api/home/signin-google`,
  getUserById: `${domain}/api/User/GetById?UserId=`,
};

export const msgURLs = {
  sendMsg: `${domain}/api/ChatMessage/SendMessage`,
  readStatus: `${domain}/api/ChatMessage/ReadStatus?UserId=`,
  msgListUser: `${domain}/api/ChatMessage/GetByUser?UserId=`,
  getChatsUser: `${domain}/api/ChatMessage/GetChatByUser?`,
  msgListAgent: `${domain}/api/ChatMessage/GetByAgent?AgentId=`,
  getChatsAgent: `${domain}/api/ChatMessage/GetChatData?ChatId=`,
};

export const googleLogUrls = {
  signIn: `${domain}/api/home/signin-google`,
};
