import classes from './Filter.module.css';
import { useState } from 'react';

const Filter = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [value, setValue] = useState('');
  const [zIndex, setzIndex] = useState(false);

  const dropdownMouseOver = () => {
    setShowDropdown(true);
    setzIndex(true);
  };
  const dropdownMouseOut = () => {
    setShowDropdown(false);
    setzIndex(false);
  };

  let data = [...value.split(', ')];
  const checkboxHandle = (val) => {
    let newData;
    if (props.type === 'multipleOption') {
      const existing = data.find((item) => item === val);

      if (!existing) {
        data.push(val);
      } else {
        data = data.filter((item) => item !== val);
      }
      setValue(data.join(', '));
      newData = data.filter((item) => item !== '');
    } else {
      setValue(val);
      newData = val;
    }
    props.getValue(newData);
  };

  let displayLabel = value !== '' ? `: ${value.replace(', ', '')} ` : '';
  if (props.filterName === 'City')
    displayLabel =
      value !== ''
        ? `: ${value.replace(', ', '')?.replace(',', '').split(' ')[0]}`
        : '';

  return (
    <div
      className={classes.filter}
      onMouseOver={dropdownMouseOver}
      onMouseOut={dropdownMouseOut}
      style={{ zIndex: zIndex ? 1000 : 0 }}
    >
      <p
        className={`${classes.filterDisplay} ${
          value !== '' ? classes.filterDisplayHighlight : ''
        }`}
      >
        {`${props.filterName} ${displayLabel}`}
        <span className="material-symbols-outlined">arrow_drop_down</span>
      </p>
      {showDropdown && (
        <div className={classes.filterDropdown}>
          {props.options.map((item, i) => (
            <div className={classes.filterCheckDiv} key={i}>
              <input
                id={`${props.filterName}-${i}`}
                className={classes.filterCheck}
                type={props.type === 'singleOption' ? 'radio' : 'checkbox'}
                name={
                  props.type === 'singleOption' ? 'singleCheckFilter' : null
                }
                defaultChecked={
                  props.type === 'singleOption'
                    ? value === item.value
                    : [...value.split(', ')].includes(item.value)
                }
                onChange={checkboxHandle.bind(null, item.value)}
              ></input>
              <label
                htmlFor={`${props.filterName}-${i}`}
                className={classes.filterCheckLabel}
              >
                {item.label}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Filter;
