import Filters from '../components/header/Filters';
import VideoList from '../components/video/VideoList';

const HomePage = () => {
  return (
    <>
      <Filters />
      <VideoList />
    </>
  );
};
export default HomePage;
