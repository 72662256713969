import { agentURLs } from '../../../urls/userURLs';
import classes from './AgentRegister.module.css';
import Input from '../Input';
import { useRef, useState } from 'react';

const AgentRegister = () => {
  // const [license, setLicense] = useState();
  const [submitMsg, setSubmitMsg] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);

  const emailRef = useRef();
  const nameRef = useRef();
  const passRef = useRef();
  const numberRef = useRef();

  // const licenseHandle = (e) => {
  //   setLicense(e.target.files[0]);
  // };

  const registerHandle = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (
      emailRef.current.value.trim().length === 0 ||
      nameRef.current.value.trim().length === 0 ||
      passRef.current.value.trim().length === 0
    ) {
      setSubmitMsg('Please enter fields marked with asterisk.');
      setResponseStatus(false);
    } else if (passRef.current.value.trim().length < 8) {
      setSubmitMsg('Please enter password of min 8 character');
      setResponseStatus(false);
    } else {
      const body = new FormData();
      body.append('Email', emailRef.current.value);
      body.append('Password', passRef.current.value);
      body.append('Name', nameRef.current.value);
      body.append('PhoneNumber', numberRef.current.value);
      // body.append('LicenseFile', license);

      const response = await fetch(agentURLs.register, {
        method: 'POST',
        body,
      });
      if (!response.ok) {
        const resData = await response.json();
        setSubmitMsg(Object.values(resData.errors).join(', '));
        setResponseStatus(false);
      } else {
        const resData = await response.json();
        setSubmitMsg(resData.data);
        setResponseStatus(true);
      }
    }
    setSubmitting(false);
  };
  // function alphaOnly(event) {
  //   var key = event.keyCode;
  //   return ((key >= 65 && key <= 90) || key == 8);
  // };
  return (
    <div className={classes.agentReg}>
      <h1 className={classes.agentRegHead}>Agent Signup</h1>
      <form className={classes.agentForm}>
        <Input
          type="text"
          label="Name *"
          name="agentName"
          error="Please enter your name"
          placeholder="Name *"
          ref={nameRef}
        ></Input>
        <Input
          type="email"
          label="Email *"
          name="agentEmail"
          error="Please enter your email"
          placeholder="Email *"
          ref={emailRef}
        ></Input>
        <Input
          type="password"
          label="Password *"
          name="agentPassword"
          error="Please enter your password"
          placeholder="Password *"
          ref={passRef}
        ></Input>

        <Input
          type="number"
          label="Phone Number"
          name="agentPhoneNumber"
          error="Please enter your phone number"
          placeholder="Phone Number"
          ref={numberRef}
        ></Input>

        <button className={classes.AgentSignupBtn} onClick={registerHandle}>
          {submitting ? 'Please wait...' : 'Submit'}
        </button>
        <div className={classes.agnt_reg_logForm}>
          <p className={classes.agnt_reg_logFormMsg}>
            Are you already registered?
          </p>
          <a className={classes.agnt_reg_logBtn} href="/agent/login">
            Login
          </a>
        </div>
        <p
          className={`${
            responseStatus ? classes.submitMsgSuccess : classes.submitMsg
          }`}
        >
          {submitMsg}
        </p>
      </form>
    </div>
  );
};
export default AgentRegister;
