import classes from './AgentMsgDetail.module.css';
import { useRef, useState, useEffect } from 'react';
import { msgURLs } from '../../../urls/userURLs';
import { useParams } from 'react-router';

const AgentMsgDetail = () => {
  const msgRef = useRef();
  const [chatList, setChatList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const { chatId, customerUserId } = useParams();

  useEffect(() => {
    getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChats = async () => {
    const response = await fetch(msgURLs.getChatsAgent + chatId);
    const resData = await response.json();
    setChatList(resData.data.data);
  };

  const sendMessageHandle = async (e) => {
    setSubmitting(true);
    const body = new FormData();
    if (chatList && chatList[0]) body.append('VideoId', chatList[0].videoId);
    body.append('Sender', 'Agent');
    body.append('UserId', customerUserId);
    body.append('Message', msgRef.current.value);
    body.append('AgentId', localStorage.getItem('agentId'));
    body.append('ChatId', chatId);

    await fetch(msgURLs.sendMsg, {
      method: 'POST',
      body,
    });

    getChats();
    setSubmitting(false);
    msgRef.current.value = '';
  };

  return (
    <div className={classes.agntMsg}>
      <div className={classes.agntMsg_head}>
        <h2>Your Chats</h2>
      </div>
      <div className={classes.agntMsg_reply}>
        <ul className={classes.agntMsg_rply_list}>
          {chatList.map((item, i) => (
            <li
              className={classes.agntMsg_rply_list_item}
              key={item.chatId + i}
            >
              <img
                className={classes.agntMsg_rply_img}
                src="/images/account.png"
                alt="user"
              ></img>
              <p className={classes.agntMsg_rply_user}>
                {item.name}
                <span className={classes.custMsg_rply_user_desig}>
                  {item.sender}
                </span>
              </p>
              <p className={classes.agntMsg_rply_text}>{item.message}</p>
              <p className={classes.agntMsg_rply_time}>
                {new Date(item.createdDate).toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  month: '2-digit',
                  year: 'numeric',
                  day: '2-digit',
                })}
              </p>
            </li>
          ))}
        </ul>
        <div className={classes.agntMsg_rplyer}>
          <textarea
            className={classes.agntMsg_rplyer_txt}
            placeholder="Please type your message here..."
            rows={3}
            ref={msgRef}
          ></textarea>
          <button
            className={classes.agntMsg_rplyer_btn}
            onClick={sendMessageHandle}
          >
            {submitting ? 'Please wait...' : 'Send'}
          </button>
        </div>
      </div>
    </div>
  );
};
export default AgentMsgDetail;
