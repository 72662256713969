import classes from './Filters.module.css';
import Filter from './Filter';
import { homeURLs } from '../../urls/publicURLs';
import { useDispatch } from 'react-redux';
import { HomeActions } from '../../store/homeSlice';
import { useEffect } from 'react';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import { useState } from 'react';

const Filters = () => {
  const rentMin = 1500;
  const rentMax = 20000;
  const buyMin = 200000;
  const buyMax = 1000000;

  const [showNoFee, setsShowNoFee] = useState(true);
  const [priceThumbVal, setPriceThumbVal] = useState([1500, 20000]);
  const [priceMinVal, setPriceMinVal] = useState(rentMin);
  const [priceMaxVal, setPriceMaxVal] = useState(rentMax);
  const dispatch = useDispatch();
  useEffect(() => {
    gethomeVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterObj = {};
  const gethomeVideos = async () => {
    // Set the initial price filter if not already set
    if (!filterObj.price) {
      filterObj.price = { minValue: 1500, maxValue: 20000 };
    }
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    if (Object.keys(filterObj).length !== 0) {
      options.body = JSON.stringify(filterObj);
      dispatch(HomeActions.setFilters(filterObj));
    }
  
    const response = await fetch(homeURLs.allVideos, options);
    const resData = await response.json();
    dispatch(
      HomeActions.setVideos(
        resData.data.totalRecords === 0 ? 'noVideo' : resData.data.data
      )
    );
  };
  

  const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 4px;
    margin: 0 auto;
    position: relative;
  `;

  const StyledThumb = styled.div`
    height: 10px;
    line-height: 10px;
    width: 10px;
    text-align: center;
    background-color: #05224e;
    color: black;
    border-radius: 50%;
    cursor: grab;
    top: 50%;
    transform: translateY(-50%);
    position: relative;

    .thumb-value {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-100%);
      font-size: 12px;
    }

    &:last-child .thumb-value {
      left: 0;
      transform: translateX(0);
    }
  `;

  const Thumb = (props, state) => {
    let thumbVal = `$${
      state.valueNow > 1000000
        ? `${(state.valueNow / 1000000).toFixed(2)}M`
        : state.valueNow
    }`;

    if (state.valueNow === rentMax || state.valueNow === buyMax)
      thumbVal = `$${
        state.valueNow > 1000000
          ? `${(state.valueNow / 1000000).toFixed(2)}M+`
          : `${state.valueNow}+`
      }`;

    return (
      <StyledThumb {...props}>
        <div className="thumb-value">{thumbVal}</div>
      </StyledThumb>
    );
  };

  const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${(props) =>
      props.index === 2 ? '#ddd' : props.index === 1 ? '#0a466e' : '#ddd'};
    border-radius: 999px;
    position: relative;
  `;

  const Track = (props, state) => (
    <StyledTrack {...props} index={state.index} />
  );

  const bedFilterHandle = (data) => {
    const filterData = data.map((num) => {
      if (num === 'Studio') {
        return 0;
      } else if (num === '4+') {
        return 4;
      } else {
        return Number(num);
      }
    });
    filterObj.noOfBeds = filterData;
    gethomeVideos();
  };

  const bathFilterHandle = (data) => {
    const filterData = data.map((num) => {
      if (num === '4+') {
        return 4;
      } else {
        return Number(num);
      }
    });
    filterObj.noOfBaths = filterData;
    gethomeVideos();
  };

  const locattionHandle = (data) => {
    filterObj.city = data;
    gethomeVideos();
  };

  const priceFilterHandle = (values) => {
    filterObj.price = { minValue: values[0], maxValue: values[1] };
    gethomeVideos();
  };

  const noFeeHandle = (e) => {
    filterObj.nofee = e.target.checked ? true : undefined;
    gethomeVideos();
  };

  const dealHandle = (data) => {
    if (data === 'BUY') {
      setPriceThumbVal([buyMin, buyMax]);
      setPriceMinVal(buyMin);
      setPriceMaxVal(buyMax);
      filterObj.price = { minValue: buyMin, maxValue: buyMax };
      setsShowNoFee(false);
    } else if (data === 'RENT') {
      setPriceThumbVal([rentMin, rentMax]);
      setPriceMinVal(rentMin);
      setPriceMaxVal(rentMax);
      filterObj.price = { minValue: rentMin, maxValue: rentMax };
      setsShowNoFee(true);
    } else {
      // Handle "Any" or no selection case
      const minPrice = rentMin;
      const maxPrice = buyMax;
      setPriceThumbVal([minPrice, maxPrice]);
      setPriceMinVal(minPrice);
      setPriceMaxVal(maxPrice);
      filterObj.price = { minValue: minPrice, maxValue: maxPrice };
      setsShowNoFee(true);  // Adjust this based on your requirement
    }
    gethomeVideos();
  };

  return (
    <>
      <div className={classes.filters}>
        <Filter
          filterName="Bedroom"
          getValue={bedFilterHandle}
          type="multipleOption"
          options={[
            { value: 'Studio', label: 'Studio' },
            { value: '1', label: '1 Bed' },
            { value: '2', label: '2 Bed' },
            { value: '3', label: '3 Bed' },
            { value: '4+', label: '4+ Bed' },
          ]}
        />
        <Filter
          filterName="Bathroom"
          getValue={bathFilterHandle}
          type="multipleOption"
          options={[
            { value: '1', label: '1 Bath' },
            { value: '2', label: '2 Bath' },
            { value: '3', label: '3 Bath' },
            { value: '4+', label: '4+ Bath' },
          ]}
        />
        <Filter
          filterName="City"
          getValue={locattionHandle}
          type="multipleOption"
          options={[
            { value: 'Manhattan', label: 'Manhattan' },
            { value: 'Brooklyn', label: 'Brooklyn' },
            { value: 'Bronx', label: 'Bronx' },
            { value: 'Queens', label: 'Queens' },
            { value: 'Long Island', label: 'Long Island' },
          ]}
        />
        <Filter
          filterName="Deal Type"
          getValue={dealHandle}
          type="singleOption"
          options={[
            { value: 'Any', label: 'Any' },
            { value: 'RENT', label: 'Rent' },
            { value: 'BUY', label: 'Buy' },
          ]}
        />

        {showNoFee && (
          <div className={classes.noFee}>
            <label htmlFor="NoFeeFilter">No fee </label>
            <input
              type="checkbox"
              id="NoFeeFilter"
              onChange={noFeeHandle}
            ></input>
          </div>
        )}
        <div className={classes.priceFilter}>
          <label>Price</label>
          <div className={classes.priceFilterBox}>
            <StyledSlider
              onChange={priceFilterHandle}
              defaultValue={priceThumbVal}
              renderTrack={Track}
              renderThumb={Thumb}
              min={priceMinVal}
              max={priceMaxVal}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Filters;
