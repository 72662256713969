import classes from './AgentDashboard.module.css';
import SideNav from '../../header/SideNav';
import { Outlet } from 'react-router';

const AgentDashboard = () => {
  const links = [
    {
      activeTab: 'messages',
      url: '/agent/messages',
      googleIconName: 'mail',
      title: 'Messages',
    },
    {
      activeTab: 'video',
      url: '/agent/video',
      googleIconName: 'movie',
      title: 'Video',
    },
    {
      activeTab: 'profile',
      url: '/agent/profile',
      googleIconName: 'person',
      title: 'Profile',
    },
    {
      activeTab: 'change-password',
      url: '/agent/change-password',
      googleIconName: 'key',
      title: 'Change Password',
    },
  ];
  return (
    <div className={classes.agnt_acc}>
      <SideNav reduxLogout={'Agent'} links={links} />
      <Outlet />
    </div>
  );
};
export default AgentDashboard;
