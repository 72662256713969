import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Circle,
} from '@react-google-maps/api';

const GoogleMapViewer = ({
  style = {
    width: '100%',
    height: '490px',
  },
  center,
  zoom = 13,
  hideMap = false,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDWVA34PTvtvFaM6D4RT8HeG9Ykq6BZV0M',
  });

  return (
    <div>
      {isLoaded && (
        <GoogleMap center={center} mapContainerStyle={style} zoom={zoom}>
          {!hideMap && <Marker position={center} />}
          {hideMap && (
            <Circle
              options={{
                strokeColor: '#1890ad',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#1890ad',
                fillOpacity: 0.35,
                center: center,
                radius: 140, // specify the radius in meters
              }}
            />
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default GoogleMapViewer;
