import { agentURLs, googleLogUrls } from '../../../urls/userURLs';
import classes from './AgentLogin.module.css';
import Input from '../Input';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AgentActions } from '../../../store/agentSlice';
import { jwtDecode } from 'jwt-decode';

const AgentLogin = () => {
  const [submitMsg, setSubmitMsg] = useState();
  const [responseStatus, setResponseStatus] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoogleResponse = async (res) => {
    const decoded = jwtDecode(res.credential);

    const body = new FormData();

    if (decoded) {
      body.append('aud', decoded.aud);
      body.append('azp', decoded.azp);
      body.append('email', decoded.email);
      body.append('email_verified', decoded.email_verified);
      body.append('exp', decoded.exp);
      body.append('given_name', decoded.given_name);
      body.append('iat', decoded.iat);
      body.append('iss', decoded.iss);
      body.append('jti', decoded.jti);
      body.append('locale', decoded.locale);
      body.append('name', decoded.name);
      body.append('nbf', decoded.nbf);
      body.append('picture', decoded.picture);
      body.append('sub', decoded.sub);
      body.append('usertype', 'Agent');

      const res = await fetch(googleLogUrls.signIn, {
        method: 'POST',
        body,
      });
      const resData = await res.json();

      if (resData.data.status === true && resData.data.role === 'Agent') {
        localStorage.setItem('name', resData.data.name);
        localStorage.setItem('role', resData.data.role);
        localStorage.setItem('status', resData.data.status);
        localStorage.setItem('token', resData.data.token);
        localStorage.setItem('userId', resData.data.userId);
        localStorage.setItem('agentId', resData.data.agentId);
        localStorage.setItem('msgCount', resData.data.messageCount);
        dispatch(AgentActions.updateLoginStatus(true));
        setResponseStatus(true);
        navigate(
          `/agent/${resData.data.messageCount > 0 ? 'messages' : 'video'}`
        );
      } else {
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        localStorage.removeItem('status');
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('agentId');
        localStorage.removeItem('msgCount');
        setResponseStatus(false);
        setSubmitMsg('Access denied');
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (google) {
      // eslint-disable-next-line no-undef
      google.accounts.id.initialize({
        client_id:
          '1033443409098-7h9dt6pgddtqrn9vsn4op0gs8n9od4tg.apps.googleusercontent.com',
        callback: handleGoogleResponse,
      });
      // eslint-disable-next-line no-undef
      google.accounts.id.renderButton(
        document.getElementById('googleSigInBtnAgent'),
        {
          width: 240,
          height: 50,
          longtitle: true,
          theme: 'dark',
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailRef = useRef();
  const passwordRef = useRef();

  const loginHandle = async (e) => {
    setSubmitting(true);
    if (
      emailRef.current.value.trim().length === 0 ||
      passwordRef.current.value.trim().length === 0
    ) {
      setSubmitMsg('Please enter both email and password');
      setResponseStatus(false);
    } else {
      setSubmitMsg('');
      e.preventDefault();
      const response = await fetch(agentURLs.login, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailRef.current.value,
          password: passwordRef.current.value,
          userType: 'Agent',
        }),
      });
      const resData = await response.json();
      if (resData.data.status === true && resData.data.role === 'Agent') {
        localStorage.setItem('name', resData.data.name);
        localStorage.setItem('role', resData.data.role);
        localStorage.setItem('status', resData.data.status);
        localStorage.setItem('token', resData.data.token);
        localStorage.setItem('userId', resData.data.userId);
        localStorage.setItem('agentId', resData.data.agentId);
        localStorage.setItem('msgCount', resData.data.messageCount);
        setResponseStatus(true);
        dispatch(AgentActions.updateLoginStatus(true));
        navigate(
          `/agent/${resData.data.messageCount > 0 ? 'messages' : 'video'}`
        );
      } else {
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        localStorage.removeItem('status');
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('agentId');
        localStorage.removeItem('msgCount');
        setResponseStatus(false);
        setSubmitMsg(resData.data.message);
      }
    }
    setSubmitting(false);
  };

  const signupRedirect = () => {
    navigate('/agent/signup');
  };

  return (
    <div className={classes.agnt_log}>
      <h1 className={classes.agnt_logHead}>Agent Login</h1>
      <form className={classes.agnt_logForm}>
        <Input
          type="text"
          label="Email"
          name="agentLogEmail"
          error="Please enter your email"
          placeholder="Email"
          ref={emailRef}
        ></Input>
        <Input
          type="password"
          label="Password"
          name="agentLogPass"
          error="Please enter your password"
          placeholder="Password"
          ref={passwordRef}
        ></Input>
        <button className={classes.AgentLoginBtn} onClick={loginHandle}>
          {submitting ? 'Please Wait...' : 'Login'}
        </button>
        <div className={classes.agnt_log_SignUpForm}>
          <p className={classes.agnt_log_signupMsg}>
            Don't have an agent account?
          </p>
          <button
            onClick={signupRedirect}
            className={classes.agnt_log_signupBtn}
          >
            Signup
          </button>
        </div>
        <p
          className={`${
            responseStatus ? classes.submitMsgSuccess : classes.submitMsg
          }`}
        >
          {submitMsg}
        </p>
        <div id="googleSigInBtnAgent"></div>
      </form>
    </div>
  );
};
export default AgentLogin;
