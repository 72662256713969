import classes from './CustMsgDetail.module.css';
import { useRef, useState, useEffect } from 'react';
import { msgURLs } from '../../../urls/userURLs';
import { useParams } from 'react-router-dom';
import { homeURLs } from '../../../urls/publicURLs';

const CustMsgDetail = () => {
  const msgRefCust = useRef();
  const [chatList, setChatList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [agentId, setAgentId] = useState(null);
  const { videoId } = useParams();

  useEffect(() => {
    getChats();
  }, []);

  const getChats = async () => {
    const response = await fetch(
      msgURLs.getChatsUser +
        `VideoId=${videoId}&UserId=${localStorage.getItem('userId')}`
    );
    const resData = await response.json();
    setChatList(resData.data.data);

    // Get agentId
    const videoDetailsResponse = await fetch(
      homeURLs.getVideoDetailsById + videoId
    );
    const resDataVDetails = await videoDetailsResponse.json();
    console.log(resDataVDetails);

    setAgentId(resDataVDetails.data[0].agentId);
  };

  const sendMessageHandle = async (e) => {
    setSubmitting(true);
    const body = new FormData();
    body.append('VideoId', videoId);
    body.append('Sender', 'Customer');
    body.append('UserId', localStorage.getItem('userId'));
    body.append('Message', msgRefCust.current.value);
    body.append('AgentId', agentId);
    if (chatList && chatList[0]) body.append('ChatId', chatList[0].chatId);

    await fetch(msgURLs.sendMsg, {
      method: 'POST',
      body,
    });

    getChats();
    setSubmitting(false);
    msgRefCust.current.value = '';
  };

  return (
    <div className={classes.custMsg}>
      <div className={classes.custMsg_head}>
        <h2>Your Chats</h2>
      </div>
      <div className={classes.custMsg_reply}>
        <ul className={classes.custMsg_rply_list}>
          {chatList
            .slice(0)
            .reverse()
            .map((item) => (
              <li
                className={classes.custMsg_rply_list_item}
                key={item.chatMessageId}
              >
                <img
                  className={classes.custMsg_rply_img}
                  src="/images/account.png"
                  alt="user"
                ></img>
                <p className={classes.custMsg_rply_user}>
                  {item.name}
                  <span className={classes.custMsg_rply_user_desig}>
                    {item.sender}
                  </span>
                </p>
                <p className={classes.custMsg_rply_text}>{item.message}</p>
                <p className={classes.custMsg_rply_time}>
                  {new Date(item.createdDate).toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    month: '2-digit',
                    year: 'numeric',
                    day: '2-digit',
                  })}
                </p>
              </li>
            ))}
        </ul>
        <div className={classes.custMsg_rplyer}>
          <textarea
            className={classes.custMsg_rplyer_txt}
            placeholder="Please type your message here..."
            defaultValue={
              chatList?.length === 0
                ? "Hi, I am interested in the property. Let's discuss."
                : ''
            }
            rows={3}
            ref={msgRefCust}
          ></textarea>
          <button
            className={classes.custMsg_rplyer_btn}
            onClick={sendMessageHandle}
          >
            {submitting ? 'Please wait...' : 'Send'}
          </button>
        </div>
      </div>
    </div>
  );
};
export default CustMsgDetail;
