import classes from './VideoForm.module.css';
import './paginationStyle.css';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgentActions } from '../../../store/agentSlice';
import { agentURLs } from '../../../urls/userURLs';
import { videosloader } from '../../../store/agentSlice';
import { homeURLs } from '../../../urls/publicURLs';
import formatDate from '../../../utils/formatDate';
import Flatpickr from 'react-flatpickr';
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
const libraries = ["places"];
const VideoForm = () => {
  const [showBathMenu, setShowBathMenu] = useState(false);
  const [showBedMenu, setShowBedMenu] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const [vidFile, setVidFile] = useState(null);
  const [showList, setShowList] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitMsg, setSubmitMsg] = useState('');
  // for redio btn
  const [vidStatusInfo, setVidStatusInfo] = useState(false);
  const [mapStatusInfo, setmapStatusInfo] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Current page
  const itemsPerPage = 10; // Number of records per page
  const [deal, setDeal] = useState(false);
  const [noFee, setNoFee] = useState(false);
  const [vidStatus, setVidStatus] = useState(false);
  const [mapStatus, setMapStatus] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const vidRef = useRef();

  // redux
  const dispatch = useDispatch();
  const state = useSelector((state) => state.agent.account);
  console.log('videos', state.videoList.length)
  const paginatedVideos = state.videoList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    dispatch(videosloader());
  }, [dispatch]);


  useEffect(() =>{
    setAddress(state.video.Address);
  },[state.video.Address]);
  // For select options
  const bathMouseOverHandle = () => setShowBathMenu(true);
  const bathMouseOutHandle = () => setShowBathMenu(false);
  const bathOptCloseHandle = () => setShowBathMenu(false);

  const bedMouseOverHandle = () => setShowBedMenu(true);
  const bedMouseOutHandle = () => setShowBedMenu(false);
  const bedOptCloseHandle = () => setShowBedMenu(false);

  // For radio btn
  const vidStatusInfoOnHover = (e) => {
    e.stopPropagation();
    setVidStatusInfo(true);
  };
  const vidStatusInfoOnOut = () => setVidStatusInfo(false);

  const vidMapInfoOnHover = (e) => {
    e.stopPropagation();
    setmapStatusInfo(true);
  };
  const vidMapInfoOnOut = () => setmapStatusInfo(false);

  // dealOver
  const dealOverHandle = () => setDeal(true);
  const dealOutHandle = () => setDeal(false);
  const dealOptCloseHandle = () => setDeal(false);
  // vidOver
  const vidOverHandle = () => setVidStatus(true);
  const vidOutHandle = () => setVidStatus(false);
  const vidOptCloseHandle = () => setVidStatus(false);
  // mapOver
  const mapOverHandle = () => setMapStatus(true);
  const mapOutHandle = () => setMapStatus(false);
  const mapOptCloseHandle = () => setMapStatus(false);
  // noFeeOver
  const noFeeOverHandle = () => setNoFee(true);
  const noFeeOutHandle = () => setNoFee(false);
  const noFeeOptCloseHandle = () => setNoFee(false);

  const DealTypeHandle = (deal) =>
    dispatch(AgentActions.addVideoData({ key: 'DealType', value: deal }));

  const NoFeeTypeHandle = (answer) =>
    dispatch(AgentActions.addVideoData({ key: 'NoFee', value: answer }));

  const VideoStatusHandle = (status) =>
    dispatch(AgentActions.addVideoData({ key: 'IsActive', value: status }));

  const MapVisibilityHandle = (status) =>
    dispatch(AgentActions.addVideoData({ key: 'HideMap', value: status }));

  const ApartmentNoHandle = (e) =>
    dispatch(
      AgentActions.addVideoData({ key: 'ApartmentNo', value: e.target.value })
    );

  const bedHandle = (d) =>
    dispatch(
      AgentActions.addVideoData({
        key: 'NoOfBedRooms',
        value: d,
      })
    );

  const bathHandle = (d) =>
    dispatch(AgentActions.addVideoData({ key: 'NoOfBathRooms', value: d }));

  const priceHandle = (e) =>
    dispatch(
      AgentActions.addVideoData({ key: 'Price', value: e.target.value })
    );

  const startDateHandle = (val) => {
    dispatch(
      AgentActions.addVideoData({
        key: 'StartDate',
        value: formatDate(val),
      })
    );
    dispatch(
      AgentActions.addVideoData({
        key: 'EndDate',
        value: formatDate(val),
      })
    );
  };
  const [address, setAddress] = useState(state.video.Address);
  const [locationList, setLocationList] = useState(state.locationList);
  const [Location, setLocation] = useState(state.video.Location);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDWVA34PTvtvFaM6D4RT8HeG9Ykq6BZV0M", // Replace with your API key
    libraries,
  });
  const handlePlaceSelect = (place) => {
    if (place) {
      setAddress(place.formatted_address);
      setLocation(place.geometry.location);
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      locationHandle(place);
      setLatitude(lat);
      setLongitude(lng);

      dispatch(AgentActions.addVideoData({ key: 'PropertyLatitude', value: lat }));
      dispatch(AgentActions.addVideoData({ key: 'PropertyLongitude', value: lng }));
  
      dispatch(AgentActions.addVideoData({ key: 'Address', value: place.formatted_address }));
      dispatch(AgentActions.addVideoData({ key: 'Location', value: place.geometry.location }));
      // Update the state with selected place details
    }
  };

  const onLoad = (autocomplete) => {
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      handlePlaceSelect(place);
    });
  };
  const endDateHandle = (val) =>
    dispatch(
      AgentActions.addVideoData({
        key: 'EndDate',
        value: formatDate(val),
      })
    );

  const locationHandle = async (e) => {
    if (e !== undefined) {
      // Check if e has a target property (i.e., it’s an event object)
      const value = e.formatted_address;
      dispatch(AgentActions.addVideoData({ key: 'Address', value: value }));
      setShowList(true);
      setLocation(e.geometry.location);
      dispatch(AgentActions.addVideoData({ key: 'Location', value: e.geometry.location }));

      if (value.trim().length > 2) {
        const response = await fetch(homeURLs.getLocation + value);
        const resData = await response.json();
        dispatch(AgentActions.locationQueryList(resData.data));
      }
    }

  };


  const cityHandle = (e) => {
    const val = e.target.value;
    if (val === 'Select city') {
      dispatch(AgentActions.addVideoData({ key: 'City', value: '' }));
    } else {
      dispatch(AgentActions.addVideoData({ key: 'City', value: val }));
    }
  };

  const locationOnClick = (id) => {
    dispatch(AgentActions.setLocationDetails(id));
    setShowList(false);
  };

  const videoUploadHandle = (e) => {
    const file = e.target.files[0];
    setVidFile(file);
    if (file) {
      const videoURL = URL.createObjectURL(file);
      dispatch(
        AgentActions.addVideoData({
          key: 'VideoFile',
          value: videoURL,
        })
      );
    }
  };

  const onSubmitHandle = async () => {
    const exceptions = [
      'PropertyLatitude',
      'PropertyLongitude',
      'LocationLatitude',
      'LocationLongitude',
      'Address',
      'Country',
      'VideoId',
      'AgentId',
      'StartDate',
      'EndDate',
      'DealType',
      'ApartmentNo',
    ];
    let errors = [];

    Object.entries(state.video).forEach((elm) => {
      if (elm[1] === '' && !exceptions.includes(elm[0])) {
        errors.push(elm[0]);
      }
    });

    if (errors[0]) {
      errors.forEach((elm, i) => {
        if (elm === 'NoOfBedRooms') errors[i] = 'no. of bedrooms';
        if (elm === 'NoOfBathRooms') errors[i] = 'no. of bathrooms';
        if (elm === 'Price') errors[i] = 'price';
        if (elm === 'Location') errors[i] = 'location';
        if (elm === 'VideoFile') errors[i] = 'video';
      });
      setSubmitMsg(`Please enter ${errors.join(', ')}.`);
    } else {
      setSubmitting(true);
      const body = new FormData();
      body.append('ApartmentNo', state.video.ApartmentNo);
      body.append('AgentId', localStorage.getItem('agentId'));
      body.append('PropertyLatitude', state.video.PropertyLatitude);
      body.append('PropertyLongitude', state.video.PropertyLongitude);
      body.append('LocationLongitude', state.video.PropertyLongitude);
      body.append('LocationLatitude', state.video.PropertyLatitude);
      body.append('VideoName', '');
      body.append('DealType', state.video.DealType);
      body.append('Price', state.video.Price);
      // body.append('UpdatedDate', new Date(Date.now()));
      // body.append('VideoSrc', '');
      body.append('City', state.video.City);
      body.append('NoOfBathRooms', state.video.NoOfBathRooms);
      body.append(
        'NoOfBedRooms',
        state.video.NoOfBedRooms === 'Studio' ? 0 : state.video.NoOfBedRooms
      );
      body.append('IsActive', state.video.IsActive);
      body.append('Address', state.video.Address);
      body.append('Location', state.video.Location);
      // body.append('CreatedDate', new Date(Date.now()));
      if (state.httpMethod === 'PUT')
        body.append('VideoId', state.video.VideoId);
      body.append('Country', state.video.Country);
      body.append('VideoFile', vidFile);
      body.append('HideMap', state.video.HideMap);
      body.append('PropertyLatitude', state.video.PropertyLatitude);
      body.append('PropertyLongitude', state.video.PropertyLongitude);
      body.append('LocationLatitude', state.video.PropertyLatitude);
      body.append('LocationLongitude', state.video.PropertyLongitude);
      body.append('StartDate', state.video.StartDate);
      body.append('EndDate', state.video.EndDate);
      body.append('NoFee', state.video.NoFee);
      body.append('PropertyLatitude', latitude);
      body.append('PropertyLongitude', longitude);

      const response = await fetch(
        state.httpMethod === 'PUT'
          ? agentURLs.modifyVideo
          : agentURLs.uploadVideo,
        {
          method: state.httpMethod,
          body,
        }
      );

      dispatch(AgentActions.resetVideoForm());
      vidRef.current.value = '';

      const resData = await response.json();
      if (!response.ok) {
        setResponseStatus(false);
        setSubmitMsg(
          (resData && resData.data) ||
          (resData &&
            resData.errors &&
            Object.values(resData.errors).join(', '))
        );
      } else {
        setResponseStatus(true);
        setSubmitMsg(resData.data);
        dispatch(videosloader());
      }
      setSubmitting(false);
    }
  };

  const cancelEditingHandle = () => {
    setSubmitMsg('');
    dispatch(AgentActions.resetVideoForm());
    vidRef.current.value = '';
  };

  const editHandle = (vidID) => {
    setSubmitMsg('');
    dispatch(AgentActions.videoUpdater(vidID));
    dispatch(videosloader());
  };

  const deleteHandle = async (vidID) => {
    const confirm = window.confirm('Do you want to delete?');
    if (confirm) {
      const response = await fetch(agentURLs.deleteVideo + vidID, {
        method: 'DELETE',
      });
      if (response.ok) {
        dispatch(videosloader());
      }
    }
  };

  return (
    <div>
      <div className={classes.agnt_vid_form}>
        <h1 className={classes.agnt_vid_Head}>Video details</h1>
        <div className={classes.upperSection}>
          <video
            className={classes.agnt_video}
            key={state.video.VideoFile}
            autoPlay
            controls
          >
            <source src={state.video.VideoFile} type="video/mp4"></source>
          </video>
        </div>

        <div
          className={classes.vid_selects}
          onMouseOver={bedMouseOverHandle}
          onMouseOut={bedMouseOutHandle}
        >
          <label className={classes.inputLabel}>
            Bedroom<span className={classes.asterick}>*</span>
          </label>
          <p className={classes.vid_select_display}>
            Bedroom
            {state.video.NoOfBedRooms !== ''
              ? `: ${state.video.NoOfBedRooms}`
              : ''}{' '}
            &#9207;
          </p>
          {showBedMenu && (
            <div
              className={classes.vid_select_optBox_ext}
              onClick={bedOptCloseHandle}
            >
              <div className={classes.vid_select_optBox}>
                <p
                  className={classes.vid_select_opt}
                  onClick={bedHandle.bind(null, 'Studio')}
                >
                  Studio
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={bedHandle.bind(null, '1')}
                >
                  1 Bed
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={bedHandle.bind(null, '2')}
                >
                  2 Bed
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={bedHandle.bind(null, '3')}
                >
                  3 Bed
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={bedHandle.bind(null, '4')}
                >
                  4+ Bed
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className={classes.vid_selects}
          onMouseOver={bathMouseOverHandle}
          onMouseOut={bathMouseOutHandle}
        >
          <label className={classes.inputLabel}>
            Bathroom <span className={classes.asterick}>*</span>
          </label>
          <p className={classes.vid_select_display}>
            Bathroom
            {state.video.NoOfBathRooms !== ''
              ? `: ${state.video.NoOfBathRooms}`
              : ''}{' '}
            &#9207;
          </p>
          {showBathMenu && (
            <div
              className={classes.vid_select_optBox_ext}
              onClick={bathOptCloseHandle}
            >
              <div className={classes.vid_select_optBox}>
                <p
                  className={classes.vid_select_opt}
                  onClick={bathHandle.bind(null, '1')}
                >
                  1 Bath
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={bathHandle.bind(null, '2')}
                >
                  2 Bath
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={bathHandle.bind(null, '3')}
                >
                  3 Bath
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={bathHandle.bind(null, '4')}
                >
                  4+ Bath
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className={classes.vid_selects}
          onMouseOver={dealOverHandle}
          onMouseOut={dealOutHandle}
        >
          <label className={classes.inputLabel}>
            Deal Type<span className={classes.asterick}>*</span>
          </label>
          <p className={classes.vid_select_display}>
            {state.video.DealType} &#9207;
          </p>
          {deal && (
            <div
              className={classes.vid_select_optBox_ext}
              onClick={dealOptCloseHandle}
            >
              <div className={classes.vid_select_optBox}>
                <p
                  className={classes.vid_select_opt}
                  onClick={DealTypeHandle.bind(null, 'RENT')}
                >
                  RENT
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={DealTypeHandle.bind(null, 'BUY')}
                >
                  BUY
                </p>
              </div>
            </div>
          )}
        </div>

        {state.video.DealType === 'RENT' && (
          <div
            className={classes.vid_selects}
            onMouseOver={noFeeOverHandle}
            onMouseOut={noFeeOutHandle}
          >
            <label className={classes.inputLabel}>
              No Fee Rental<span className={classes.asterick}>*</span>
            </label>
            <p className={classes.vid_select_display}>
              {state.video.NoFee ? 'Yes' : 'No'} &#9207;
            </p>
            {noFee && (
              <div
                className={classes.vid_select_optBox_ext}
                onClick={noFeeOptCloseHandle}
              >
                <div className={classes.vid_select_optBox}>
                  <p
                    className={classes.vid_select_opt}
                    onClick={NoFeeTypeHandle.bind(null, true)}
                  >
                    Yes
                  </p>
                  <p
                    className={classes.vid_select_opt}
                    onClick={NoFeeTypeHandle.bind(null, false)}
                  >
                    No
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={classes.vid_selects}
          onMouseOver={vidOverHandle}
          onMouseOut={vidOutHandle}
        >
          <label className={classes.inputLabel}>
            Video Status<span className={classes.asterick}>*</span>
          </label>
          <div className={classes.infoForRadio}>
            <button
              className={classes.info_btn}
              onMouseOver={vidStatusInfoOnHover}
              onMouseOut={vidStatusInfoOnOut}
            >
              i
            </button>
            {vidStatusInfo && (
              <p className={classes.vid_radio_visibility_remark}>
                Choose <strong>"LIVE"</strong> under
                <strong> "Video Status"</strong> if you wish to keep the video
                live in public.
              </p>
            )}
          </div>
          <p className={classes.vid_select_display}>
            {state.video.IsActive ? 'LIVE' : 'HIDDEN'} &#9207;
          </p>
          {vidStatus && (
            <div
              className={classes.vid_select_optBox_ext}
              onClick={vidOptCloseHandle}
            >
              <div className={classes.vid_select_optBox}>
                <p
                  className={classes.vid_select_opt}
                  onClick={VideoStatusHandle.bind(null, true)}
                >
                  LIVE
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={VideoStatusHandle.bind(null, false)}
                >
                  HIDDEN
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className={classes.vid_selects}
          onMouseOver={mapOverHandle}
          onMouseOut={mapOutHandle}
        >
          <label className={classes.inputLabel}>
            Map Visibility<span className={classes.asterick}>*</span>
          </label>
          <div className={classes.infoForRadio}>
            <button
              className={classes.info_btn}
              onMouseOver={vidMapInfoOnHover}
              onMouseOut={vidMapInfoOnOut}
            >
              i
            </button>
            {mapStatusInfo && (
              <p className={classes.vid_radio_visibility_remark}>
                Choose <strong>"SHOW"</strong> under
                <strong>"Map Visibility"</strong> if you wish to pinpoint the
                precise location of the property.
              </p>
            )}
          </div>
          <p className={classes.vid_select_display}>
            {state.video.HideMap ? 'HIDE' : 'SHOW'} &#9207;
          </p>
          {mapStatus && (
            <div
              className={classes.vid_select_optBox_ext}
              onClick={mapOptCloseHandle}
            >
              <div className={classes.vid_select_optBox}>
                <p
                  className={classes.vid_select_opt}
                  onClick={MapVisibilityHandle.bind(null, true)}
                >
                  HIDE
                </p>
                <p
                  className={classes.vid_select_opt}
                  onClick={MapVisibilityHandle.bind(null, false)}
                >
                  SHOW
                </p>
              </div>
            </div>
          )}
        </div>

        <div className={classes.inputDiv}>
          <label className={classes.inputLabel}>
            Price<span className={classes.asterick}>*</span>
          </label>
          <input
            className={classes.vidInputs}
            type="number"
            placeholder="Price"
            onChange={priceHandle}
            value={state.video.Price}
            min={0}
          ></input>
        </div>

        <div className={classes.vidFileInput}>
          <label>
            Please click to upload video
            <span className={classes.asterick}>*</span>
          </label>
          <input
            type="file"
            accept="video/*"
            onChange={videoUploadHandle}
            ref={vidRef}
          ></input>
        </div>
        {state.video.DealType === 'RENT' && (
          <div className={classes.inputDiv}>
            <label className={classes.inputLabel}>
              Available From<span className={classes.asterick}>*</span>
            </label>
            <Flatpickr
              options={{
                minDate: formatDate(Date.now()),
                dateFormat: 'm-d-Y',
              }}
              className={classes.vidInputs}
              style={{ fontFamily: 'inherit' }}
              value={state.video.StartDate}
              onChange={startDateHandle}
            />
          </div>
        )}
        {state.video.DealType === 'RENT' && (
          <div className={classes.inputDiv}>
            <label className={classes.inputLabel}>
              End Date<span className={classes.asterick}>*</span>
            </label>
            <Flatpickr
              options={{
                minDate: formatDate(state.video.StartDate),
                dateFormat: 'm-d-Y',
              }}
              className={classes.vidInputs}
              style={{ fontFamily: 'inherit' }}
              value={state.video.EndDate}
              onChange={endDateHandle}
            />
          </div>
        )}
        <div className={classes.locationInput}>
          <label className={classes.inputLabel}>
            Location<span className={classes.asterick}>*</span>
          </label>
          {isLoaded && (
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={locationHandle}
            >
              <input
                className={`${classes.vidInputs} ${classes.location}`}
                type="text"
                placeholder="Location"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Autocomplete>
          )}
          <div className={classes.loc_list}>
            {showList &&
              locationList.map((item) => (
                <p
                  key={item.locationId}
                  className={classes.loc_list_p}
                  onClick={locationOnClick.bind(null, item.locationId)}
                >
                  {item.address}
                </p>
              ))}
          </div>
        </div>

        <div className={classes.inputDiv}>
          <label className={classes.inputLabel}>
            Apartment, Suite, House no. etc.
          </label>
          <input
            className={classes.vidInputs}
            type="text"
            placeholder="Apartment, Suite, House no. etc."
            onChange={ApartmentNoHandle}
            value={state.video.ApartmentNo}
          ></input>
        </div>

        <div className={classes.inputDiv}>
          <label className={classes.inputLabel}>
            City<span className={classes.asterick}>*</span>
          </label>
          <select
            className={classes.vidInputs}
            value={state.video.City}
            onChange={cityHandle}
          >
            <option value="Select city">Select city</option>
            <option value="Manhattan">Manhattan</option>
            <option value="Brooklyn">Brooklyn</option>
            <option value="Bronx">Bronx</option>
            <option value="Queens">Queens</option>
            <option value="Long Island">Long Island</option>
          </select>
        </div>

        {state.httpMethod === 'PUT' && (
          <div className={classes.agentBtnCancelDiv}>
            <button className={classes.agentBtnCancel} onClick={onSubmitHandle}>
              {submitting
                ? 'Please wait...'
                : `${state.httpMethod === 'PUT' ? 'Update' : 'Submit'}`}
            </button>
            <button
              className={classes.agentBtnCancel}
              onClick={cancelEditingHandle}
            >
              Cancel
            </button>
          </div>
        )}
        {state.httpMethod === 'POST' && (
          <button className={classes.agentBtn} onClick={onSubmitHandle}>
            {submitting
              ? 'Please wait...'
              : `${state.httpMethod === 'PUT' ? 'Update' : 'Submit'}`}
          </button>
        )}

        <p
          className={`${responseStatus ? classes.submitMsgSuccess : classes.submitMsg
            }`}
        >
          {submitMsg}
        </p>
      </div>
      <div className={classes.vidEditList}>
        <table className={classes.vidEditTable}>
          <thead className={classes.vidEditT_head}>
            <tr>
              <th>House, Suite, etc.</th>
              <th>Type</th>
              <th>Price</th>
              <th>Bed Rooms</th>
              <th>Bath Rooms</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className={classes.vidEditT_body}>
            {!state.videoList[0] ? (
              <tr>
                <td>
                  <p className={classes.loadingList}>Loading or No video...</p>
                </td>
              </tr>
            ) : (
              state.videoList.map((item) => (
                <tr key={item.videoId}>
                  <td>{item.apartmentNo}</td>
                  <td>{item.dealType}</td>
                  <td>${item.price}</td>
                  <td>
                    {item.noOfBedRooms === 0 ? 'Studio' : item.noOfBedRooms}
                  </td>
                  <td>{item.noOfBathRooms}</td>
                  <td>{item.isActive ? 'Live' : 'Hidden'}</td>
                  <td className={classes.tbl_actionBtn}>
                    <a
                      href={`/video-details/${item.videoId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="material-symbols-outlined">
                        visibility
                      </span>
                    </a>
                    <span
                      className="material-symbols-outlined"
                      onClick={editHandle.bind(null, item.videoId)}
                    >
                      edit_square
                    </span>
                    <span
                      style={{ color: 'red' }}
                      className="material-symbols-outlined"
                      onClick={deleteHandle.bind(null, item.videoId)}
                    >
                      delete
                    </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className={classes.agntMsg_Footer_mini}>
        <p className={classes.agntMsg_Footer_mini_head}>Video List</p>
        {!state.videoList[0] ? (
          <p className={classes.loadingList}>Loading or No video...</p>
        ) : (
          paginatedVideos.map((item, i) => (
            <ul className={classes.agntMsg_Footer_mini_Ul} key={i}>
              <li>
                <h4>Apartment No.</h4>
                <p>{item.apartmentNo}</p>
              </li>
              <li>
                <h4>Type</h4> <p>{item.dealType}</p>
              </li>
              <li>
                <h4>Price</h4> <p>${item.price}</p>
              </li>
              <li>
                <h4>Bed Rooms</h4>{' '}
                <p>{item.noOfBedRooms === 0 ? 'Studio' : item.noOfBedRooms}</p>
              </li>
              <li>
                <h4>Bath Rooms</h4> <p>{item.noOfBathRooms}</p>
              </li>
              <li>
                <h4>Status</h4> <p>{item.isActive ? 'Live' : 'Hidden'}</p>
              </li>
              <li>
                <h4>Action</h4>{' '}
                <p className={classes.tbl_actionBtn}>
                  <a
                    href={`/video-details/${item.videoId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  </a>
                  <span
                    className="material-symbols-outlined"
                    onClick={editHandle.bind(null, item.videoId)}
                  >
                    edit_square
                  </span>
                  <span
                    style={{ color: 'red' }}
                    className="material-symbols-outlined"
                    onClick={deleteHandle.bind(null, item.videoId)}
                  >
                    delete
                  </span>
                </p>
              </li>
            </ul>
          ))
        )}
      </div>
      <div className="pagination-controls">
        <button 
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button 
          onClick={() => setCurrentPage(prev => (prev * itemsPerPage < state.videoList.length ? prev + 1 : prev))}
          disabled={currentPage * itemsPerPage >= state.videoList.length}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default VideoForm;