import { redirect } from 'react-router-dom';

export default function getToken() {
  const token = localStorage.getItem('token');
  if (!token) return null;
  return token;
}

export const isAgent = () => {
  const token = getToken();
  const userId = localStorage.getItem('userId');
  const agentId = localStorage.getItem('agentId');
  const role = localStorage.getItem('role');

  if (token && userId && agentId && role === 'Agent') {
    return null;
  } else {
    return redirect('/');
  }
};

export const isUser = () => {
  const token = getToken();
  const userId = localStorage.getItem('userId');
  const role = localStorage.getItem('role');

  if (token && userId && role === 'Customer') {
    return null;
  } else {
    return redirect('/');
  }
};
