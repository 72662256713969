import { configureStore } from '@reduxjs/toolkit';
import AgentSlice from './agentSlice';
import CustomerSlice from './customerSlice';
import HomeSlice from './homeSlice';

const store = configureStore({
  reducer: {
    agent: AgentSlice.reducer,
    customer: CustomerSlice.reducer,
    home: HomeSlice.reducer,
  },
});

export default store;
